import { InjectedConnector } from "@web3-react/injected-connector"
//import { WalletConnectConnector } from "@web3-react/walletconnect-connector"
//import { RPC_URL } from "../../constants"

export const connections = [
  {
    connector: new InjectedConnector({}),
    image: `https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/512px-MetaMask_Fox.svg.png?20220831120339`,
    name: "MetaMask",
  },
  //   {
  //     connector: new InjectedConnector({}),
  //     image: `https://cdn.worldvectorlogo.com/logos/binance-logo.svg`,
  //     name: "Binance Chain Wallet",
  //   },
  //   {
  //     connector: new WalletConnectConnector({
  //       rpcUrl: RPC_URL,
  //       bridge: "https://bridge.walletconnect.org",
  //       qrcode: true,
  //     }),
  //     image: `https://seeklogo.com/images/W/walletconnect-logo-EE83B50C97-seeklogo.com.png`,
  //     name: "WalletConnect",
  //   },
  // {
  //   connector: new InjectedConnector({}),
  //   image: `https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/512px-MetaMask_Fox.svg.png?20220831120339`,
  //   name: "Portis",
  // },
]
