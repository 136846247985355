import target from "../images/target.png"
import bank from "../images/bank.png"
import stonks from "../images/stonks.png"
import { useEffect, useState } from "react"
import { providerReadOnly, saleUpdate } from "../../state/state"
import { ethersToRaise } from "../../API/ico"
import { Spinner } from "../spinner/Spinner"
import { parseWeiToNumber } from "../../utils"
import { useStore } from "@nanostores/react"
import { PRIVATE_ICO_ADDRESS, PUBLIC_ICO_ADDRESS, ZKDX_ICO } from "../../constants"

export const InfoBar = ({ icoAddress, info, logo }) => {
  const saleStatus = useStore(saleUpdate)
  //eslint-disable-next-line
  const [loading, setLoading] = useState({ raised: false, target: false })
  const [data, setData] = useState({})
  let lastUpdate

  const loadData = async (target = true) => {
    setLoading({ raised: true, target })

    let targetRaise = await ethersToRaise(icoAddress)

    targetRaise = parseWeiToNumber(targetRaise, 0)
    let obj = await getRaised(targetRaise)
    lastUpdate = Date.now() + 60000
    setData({ ...obj, targetRaise })
    setLoading({ raised: false, target: false })
  }

  const getRaised = async (targetRaise) => {
    if (icoAddress === PRIVATE_ICO_ADDRESS) {
      return { raised: 215.89, raisedPerc: 240 }
    }

    if (icoAddress === PUBLIC_ICO_ADDRESS) {
      return { raised: 653.61, raisedPerc: 133 }
    }

    if (icoAddress === ZKDX_ICO) {
      return { raised: 129.71, raisedPerc: 48 }
    }

    let raised = await providerReadOnly.getBalance(icoAddress)
    raised = parseWeiToNumber(raised, 5)

    if (raised == 0) raised = "0"
    const raisedPerc = ((raised / targetRaise) * 100).toFixed(0)

    return { raised, raisedPerc }
  }

  useEffect(() => {
    loadData(saleStatus === 0).then(() => {
      providerReadOnly.on("block", async () => {
        if (lastUpdate > Date.now()) return
        loadData(false)
      })
    })
  }, [saleStatus])

  return (
    <div className="w-auto bg-custom-color px-5 max-md:py-5 max-md:mx-2 text-white font-semibold rounded-md border-2 border-2 border-card-border md:h-16 h-auto grid md:grid-cols-4 md:grid-rows-1 grid-cols-2 grid-rows-2 md:gap-20 gap-5">
      <div className="flex items-center">
        <img src={target} className="h-12" />
        <div className="text-sm pl-2">
          <p className="text-gray-500">Target Raise</p>
          {loading.target ? (
            <Spinner size={"16"} otherStyle={"pl-1 pt-1"} />
          ) : (
            <p className="text-orange-color"> {info.hardCap === "TBA" ? "TBA" : data.targetRaise} ETH</p>
          )}
        </div>
      </div>
      <div className="flex items-center">
        <img src={bank} className="h-10" />
        <div className="text-sm pl-2">
          <p className="text-gray-500">Total Raised</p>
          <p className="text-orange-color">
            {data.raised} ETH ({data.raisedPerc}%)
          </p>
        </div>
      </div>
      <div className="flex items-center max-md:pl-3">
        <img src={logo} className="h-11" />
        <div className="text-sm pl-2">
          <p className="text-gray-500">Price</p>
          <p className="text-orange-color">{info.hardCap === "TBA" ? "TBA" : info.price}</p>
        </div>
      </div>
      <div className="flex items-center max-md:pl-2">
        <img src={stonks} className="h-10" />
        <div className="text-sm pl-2">
          <p className="text-gray-500">Initial Marketcap</p>
          <p className="text-orange-color">{info.hardCap === "TBA" ? "TBA" : info.init}</p>
        </div>
      </div>
    </div>
  )
}
