import { useEffect, useState } from "react"
import { Card } from "../card"
import { Sale } from "./sale"
import { Claim } from "./claim"
import { PRIVATE_ICO_ADDRESS } from "../../../constants"
import { useWeb3React } from "@web3-react/core"
import { isOnWhitelist } from "../../../API/ico"
import { Modal } from "../../modal/modal"

export const InteractionCard = ({ icoAddress, info }) => {
  const { active, account } = useWeb3React()
  const [selected, setSelected] = useState("SALE")
  //eslint-disable-next-line
  const [isOnWhite, setIsOnWhite] = useState(true)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (active) {
      if (icoAddress === PRIVATE_ICO_ADDRESS) {
        isOnWhitelist(account).then((value) => {
          setIsOnWhite(value)
          setShowModal(true)
        })
      }
    }
  }, [active, account])

  const Alert = () => {
    return (
      <div className="md:mb-96 w-72 h-16 px-4 rounded-md border-2 text-center flex justify-center items-center border-card-border shadow bg-custom-color-solid">
        {isOnWhite ? "You are whitelisted." : "You are not whitelisted."}
      </div>
    )
  }

  return (
    <>
      <Card padding="0">
        <div className="w-full h-12 border-b-2 border-card-border rounded-t-md flex flex-row overflow-hidden">
          <button
            onClick={() => {
              console.log("SALE")
              setSelected("SALE")
            }}
            className={`w-full border-r-2 border-card-border "rounded-tl-md" text-xl ${
              selected === "SALE" ? "bg-orange-color  text-white" : "text-gray-500"
            }`}
          >
            SALE
          </button>
          <button
            onClick={() => {
              console.log("CLAIM")
              setSelected("CLAIM")
            }}
            className={`w-full  "rounded-tr-md" text-xl ${
              selected === "CLAIM" ? "bg-orange-color  text-white" : "text-gray-500"
            }`}
          >
            {"CLAIM"}
          </button>
        </div>
        {selected === "SALE" ? (
          <Sale icoAddress={icoAddress} info={info} isOnWhite={isOnWhite} />
        ) : (
          <Claim icoAddress={icoAddress} info={info} isOnWhite={isOnWhite} />
        )}
      </Card>
      {showModal ? <Modal content={<Alert />} setShowModal={setShowModal} /> : ""}
    </>
  )
}
