import { Link } from "react-router-dom"
import { InteractionCard } from "./interactionCard"

export const NoPositionFound = () => {
  return (
    <InteractionCard>
      <div className="flex flex-col w-full h-full justify-center items-center text-white font-semibold gap-4 min-h-[200px]">
        <p className="text-2xl">No Position Found</p>
        <Link
          className="rounded-md border-2 border border-orange-color text-orange-color hover:text-white hover:bg-orange-color py-3 px-6"
          to={"/liquidity"}
        >
          Add Liquidity
        </Link>
      </div>
    </InteractionCard>
  )
}
