import { useWeb3React } from "@web3-react/core"
import { Link } from "react-router-dom"

export const ReceiptToast = ({ txHash }) => {
  const { chainId } = useWeb3React()
  const url = chainId === 80001 ? "mumbai.polygonscan.com/" : "explorer.zksync.io"
  return (
    <div>
      <span>Transaction Submitted, see transaction at this </span>
      <Link target="_blank" to={`https://${url}/tx/${txHash}`}>
        link
      </Link>
    </div>
  )
}
