import { useWeb3React } from "@web3-react/core"
import { addressSlice } from "../../utils"

export const WalletConnection = ({ setShowModal }) => {
  const { active, account } = useWeb3React()
  return (
    <div className="flex flex-row gap-4 h-full w-fit items-center max-md:justify-end max-md:w-full">
      <div className="h-[40px] rounded-md border-2 border-card-border flex flex-row items-center px-4 bg-custom-color-solid">
        <div className={`h-2 w-2  rounded-md  ${active ? "bg-green-500" : "bg-red-500"} `}></div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="133 0 600.32 137.91" height="18">
          <g fill="#fff">
            <g>
              <path d="m583.35,137.91h-31.72c-38.03,0-68.95-30.92-68.95-68.95S513.6,0,551.64,0s68.95,30.92,68.95,68.95v67.58h-27.58v-67.58c0-22.86-18.51-41.37-41.37-41.37s-41.37,18.51-41.37,41.37,18.51,41.37,41.37,41.37h31.72v27.58Z"></path>
              <path d="m427.52,136.53h-27.58v-67.58c0-38.03,30.92-68.95,68.95-68.95v27.58c-22.86,0-41.37,18.51-41.37,41.37v67.58Z"></path>
              <path d="m303.4,137.91c-38.03,0-68.95-30.92-68.95-68.95S265.37,0,303.4,0s68.95,30.92,68.95,68.95v13.79h-79.3v-27.58h49.32c-5.73-16.08-21.01-27.58-38.94-27.58-22.86,0-41.37,18.51-41.37,41.37s18.51,41.37,41.37,41.37c13.79,0,26.67-6.9,34.37-18.4l22.86,15.39c-12.77,19.09-34.15,30.59-57.27,30.59Z"></path>
              <path d="m729.32,27.58h-67.36v108.95h67.36V27.58Z"></path>
            </g>
          </g>
        </svg>
      </div>
      <button
        onClick={() => setShowModal(true)}
        className="h-[40px] w-auto border rounded-md border-2 border-card-border flex flex-row items-center px-4 font-semibold bg-custom-color-solid"
      >
        {!active ? "Connect" : addressSlice(account)}
      </button>
    </div>
  )
}
