import { InteractionCard } from "./interactionCard"

export const ConnectWallet = () => {
  return (
    <InteractionCard>
      <div className="flex flex-col w-full h-full justify-center items-center text-white font-semibold min-h-[200px]">
        <p className="text-2xl">Connect Wallet First</p>
      </div>
    </InteractionCard>
  )
}
