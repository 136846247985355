import { useStore } from "@nanostores/react"
import { swapConfig } from "../../state/state"
import { useEffect, useState } from "react"
import { allowance3, approve } from "../../API/erc20"
import { useWeb3React } from "@web3-react/core"
import { ROUTER_ADDRESS } from "../../constants"
import { toast } from "react-toastify"
import { ReceiptToast } from "../toast/receiptToast"
import { Spinner } from "../spinner/Spinner"
import {
  calculateSlippageAmount,
  swapTokensForTokens,
  swapTokensForETHs,
  swapETHsForTokens,
} from "../../API/router"
import { buildPath } from "../../utils"

export const RouterSwapButton = ({ updateBalance }) => {
  const { library, account } = useWeb3React()
  const [allowed, setAllowed] = useState(false)
  const [loadingButton, setLoadingButton] = useState(false)
  const swapConf = useStore(swapConfig)
  useEffect(() => {
    if (swapConf.amountOut === "0.0") swapConf.amountOut = null
    checkAllowance()
  }, [swapConf])

  const checkAllowance = async () => {
    if (swapConf.tokenA === "ETH") {
      setAllowed(true)
      return
    }
    const allowanceValue = await allowance3(account, ROUTER_ADDRESS, swapConf.tokenA, library)

    if (+allowanceValue >= +swapConf.swapValue) {
      setAllowed(true)
    } else {
      setAllowed(false)
    }
  }

  const executeApprove = async () => {
    const tx = await approve(ROUTER_ADDRESS, swapConf.swapValue, swapConf.tokenA, library)
    setLoadingButton(true)
    toast(<ReceiptToast txHash={tx.hash} />)

    if (!tx) {
      toast("Transaction error")
      return
    }

    tx.wait().then((res) => {
      if (res.status === 1) {
        toast("Transaction Success!")
        checkAllowance()
      } else toast("Transaction Failed!")

      setLoadingButton(false)
    })
  }

  const execute = async () => {
    const slippageAmount = calculateSlippageAmount(swapConf.amountOut, swapConf.slippage)

    const calculatedDeadLine = (Date.now() / 1000).toFixed(0) + swapConf.deadLine + ""

    const path = buildPath()

    if (swapConf.tokenA === "ETH") {
      swapETHsForTokens(
        swapConf.amountIn,
        slippageAmount,
        path,
        account,
        account,
        library,
        calculatedDeadLine
      )
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            return
          }
          setLoadingButton(true)
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                checkAllowance()
                updateBalance()
              } else toast("Transaction Failed!")

              setLoadingButton(false)
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
            })
        })
        .catch((e) => console.error(e))
    } else if (swapConf.tokenB === "ETH") {
      swapTokensForETHs(
        swapConf.amountIn,
        slippageAmount,
        path,
        account,
        account,
        library,
        calculatedDeadLine
      )
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            return
          }
          setLoadingButton(true)
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                checkAllowance()
                updateBalance()
              } else toast("Transaction Failed!")

              setLoadingButton(false)
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
            })
        })
        .catch(() => console.error("Transaction failed"))
    } else {
      await swapTokensForTokens(
        swapConf.amountIn,
        slippageAmount,
        path,
        account,
        account,
        library,
        calculatedDeadLine
      )
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            return
          }
          setLoadingButton(true)
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                checkAllowance()
                updateBalance()
              } else toast("Transaction Failed!")

              setLoadingButton(false)
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
            })
        })
        .catch((e) => console.error(e))
    }
  }

  return (
    <>
      {loadingButton ? (
        <button disabled className="pt-2 pb-3 w-full bg-button-color rounded-md flex justify-center">
          <Spinner size={"30"} />
        </button>
      ) : swapConf.amountOut ? (
        allowed ? (
          <button
            onClick={execute}
            className="pt-2 pb-3 w-full bg-orange-color rounded-md tex-lg font-bold text-white"
          >
            Swap
          </button>
        ) : (
          <button
            onClick={executeApprove}
            className="pt-2 pb-3 w-full bg-button-color rounded-md tex-lg font-bold text-white"
          >
            Approve
          </button>
        )
      ) : (
        <button
          disabled
          onClick={execute}
          className="pt-2 pb-3 w-full bg-button-color rounded-md tex-lg font-bold text-white"
        >
          Swap
        </button>
      )}
    </>
  )
}
