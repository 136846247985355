export const calculateApy = (totalAllocation, xSwordRewards) => {
  if (xSwordRewards) {
    const apr = (1 / +totalAllocation) * +xSwordRewards * 52

    const apy = (1 + apr / 52) ** 52 - 1

    return apy * 100
  }

  return 0
}
