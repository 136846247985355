import { InfoBar } from "./infoBar"
import { LAunchHeader } from "./launchHeader"
import { MainDetails } from "./mainDetails"
import { Card } from "./card"
import { InteractionCard } from "./interactionCard/interactionCard"
import { PRIVATE_ICO_ADDRESS } from "../../constants"
import leaf from "../images/leaf.png"
import ezkaliburMainLogo from "../images/logo.png"
import { ezkaliburUrlData } from "./pad/launchInfo"

const mainDescription = `eZKalibur is the most efficient ecosystem-focused and community-driven DEX and Launchpad build on
zkSync, our goal is to build the foundation that allows other protocols to bootstrap their liquidity
on the chain`

export const Launch = ({
  icoAddress,
  info,
  logo = leaf,
  mainLogo = ezkaliburMainLogo,
  urlData = ezkaliburUrlData,
  description = mainDescription,
}) => {
  const cardStyle = "bg-custom-color rounded-md border-2 border-2 border-card-border p-5"

  return (
    <div className="flex flex-col items-center gap-6 pt-10 w-full h-full max-md:px-2">
      <LAunchHeader urlData={urlData} mainLogo={mainLogo} description={description} />
      <div className="w-fit max-w-5xl pb-10">
        <InfoBar icoAddress={icoAddress} info={info} logo={logo} />
        <div className="w-auto text-white font-semibold mt-5 max-md:mx-2 grid max-md:gap-4">
          <div className="flex max-md:flex-col flex-row justify-between md:gap-4">
            <MainDetails className={cardStyle} icoAddress={icoAddress} info={info} />
            <InteractionCard icoAddress={icoAddress} info={info} />
          </div>
          <div className="w-auto text-white font-semibold md:mt-5 flex md:flex-row flex-col justify-between gap-4">
            <Card otherStyle="flex flex-col text-center items-center">
              <div className="w-auto md:w-[480px]">
                <p className="pb-3 text-3xl">
                  FARMING <span className="text-orange-color">OVERFLOW</span>
                </p>
                <div className="border-t w-10/12 self-center border-card-border"></div>
                <p className="pt-3">
                  Farming Overflow is an improved launch model that, while maintaining all the classic
                  Overflow benefits such as fairness and transparency it carries a substantial improvement: it
                  rewards early bird contributors. It ensures that every single ETH contributed to the pool
                  generates income until it is returned. Users will be able to farm and generate extra yield{" "}
                  {/* eslint-disable-next-line */}
                  while waiting for the sale to end. The current APR will be shown in the 'Sale' section.
                </p>
                <pv className="text-orange-color">
                  Tokens allocated to {icoAddress === PRIVATE_ICO_ADDRESS ? "Private" : "Public"} Sale Pool:{" "}
                  {info.swordAllocation}
                </pv>
              </div>
            </Card>
            <Card otherStyle="flex flex-col text-center items-center w-full">
              <p className="pb-3 text-3xl">
                <span className="text-orange-color">CLAIMING</span> PROCESS
              </p>
              <div className="border-t w-10/12 self-center border-card-border"></div>
              <p className="pt-3">
                {/* eslint-disable-next-line */}
                {info.claimingProcess} will be claimable immediately after launch. In the 'Claim' section, you
                will be able to check the invested amount, the final {info.claimingProcess} allocation and the
                ETH to be refunded.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}
