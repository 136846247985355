import arrowLeft from "../images/arrowLeft.png"
import { BiSearch } from "react-icons/bi"
import { baseToken } from "./baseToken"
import { TokenItem } from "./TokenItem"
import { useState } from "react"
import { ethers } from "ethers"
import { TokenSearch } from "./TokenSearch"

export const SelectToken = ({ selectToken, exit, name = "Swap Settings" }) => {
  const [searching, setSearching] = useState(false)
  //eslint-disable-next-line
  const [tokenSearch, setTokenSearch] = useState("")

  const handleInput = (e) => {
    setTokenSearch(e.target.value)
    if (e.target.value.length === 0) {
      setSearching(false)
      return
    }
    setSearching(true)
  }
  return (
    <>
      <div className="flex flex-col basis-2/6 bg-custom-color p-5 rounded-md border-2 border-2 border-card-border">
        <div className="flex justify-between max-w-full">
          <button onClick={() => exit(false)} className="flex items-center">
            <img src={arrowLeft} className="w-8" />{" "}
            <h5 className="text-white text-lg font-semibold ml-1">{name}</h5>
          </button>
        </div>
        {/* here need a placeholder */}
        <div className="flex item-center bg-custom-color mt-2.5 pt-2 pb-2 border border-card-border rounded-md border-2 pl-2">
          <div className="">
            <BiSearch size={26} className="bg-custom-color mt-1 text-white pr-1" />
          </div>
          <input
            type="text"
            placeholder="Enter the token symbol or address"
            className="bg-transparent w-full focus:outline-none text-white mr-3"
            onChange={handleInput}
          />
        </div>
        {/* items under search */}
        <div className="pt-3">
          {searching ? (
            !ethers.isAddress(tokenSearch) ? (
              <p className="text-white font-bold">Insert valid token address!</p>
            ) : (
              <TokenSearch address={tokenSearch} selectToken={selectToken} exit={exit} />
            )
          ) : (
            baseToken
              .filter((item) => item.display)
              .map((token, index) => {
                return (
                  <TokenItem
                    key={index}
                    name={token.name}
                    address={token.address}
                    img={token.img}
                    selectToken={selectToken}
                    exit={exit}
                  />
                )
              })
          )}
        </div>
      </div>
    </>
  )
}
