import { useState } from "react"
import { Modal } from "../../modal/modal"
import { Allocate } from "./modal/allocate"
import { removeDecimals } from "../../../utils"
import { Deallocate } from "./modal/deallocate"

export const UserAllocation = ({ userDividendsInfo, totalAllocation, xSwordBalance }) => {
  const [showAllocate, setShowAllocate] = useState(false)
  const [showDeallocate, setShowDeallocate] = useState(false)

  return (
    <>
      <div className="grid grid-cols-2 grid-rows-2 gap-4 px-4 ">
        <div className="">
          <p className="text-gray-400">Allocated Amount</p>
          <p>{removeDecimals(userDividendsInfo.allocation)} xSWORD</p>
        </div>
        <div className="">
          <p className="text-gray-400">Your Share</p>
          <p>
            {userDividendsInfo.allocation == 0
              ? "0"
              : removeDecimals((userDividendsInfo.allocation / totalAllocation) * 100)}
            %
          </p>
        </div>
        <div className="">
          <button onClick={() => setShowAllocate(true)} className="bg-orange-color rounded-md h-full w-full">
            Allocate xSWORD
          </button>
        </div>
        <div className="">
          <button
            onClick={() => setShowDeallocate(true)}
            className="border-orange-color rounded-md border-2 text-orange-color h-full w-full"
          >
            Deallocate xSWORD
          </button>
        </div>
      </div>
      {showAllocate ? (
        <Modal
          content={<Allocate userDividendsInfo={userDividendsInfo} xSwordBalance={xSwordBalance} />}
          setShowModal={setShowAllocate}
        />
      ) : (
        ""
      )}

      {showDeallocate ? (
        <Modal
          content={<Deallocate userDividendsInfo={userDividendsInfo} />}
          setShowModal={setShowDeallocate}
        />
      ) : (
        ""
      )}
    </>
  )
}
