import headingicon from "../images/headingicon.png"

import { useStore } from "@nanostores/react"
import { swapConfig } from "../../state/state"
import { Chart } from "./Chart"

export const PairInfo = ({ changeDashboard }) => {
  const swapConf = useStore(swapConfig)

  return (
    <>
      <div className="w-full">
        <div className="flex items-center pb-2">
          <h5 className="text-white font-bold text-xl">{swapConf.symbolA}</h5>
          <span className="text-pgray-color font-bold text-lg">/{swapConf.symbolB}</span>
          <button onClick={changeDashboard}>
            <img src={headingicon} className="h-6 ml-2" />
          </button>
        </div>
        {/* under hading */}
        <Chart />
      </div>
    </>
  )
}
