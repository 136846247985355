import { Contract } from "@ethersproject/contracts"
import { providerReadOnly } from "../state/state"
import DIVIDENDS_ABI from "../ABI/DIVIDENDS_ABI.json"
import { DIVIDENDS_ADDRESS } from "../constants"
import { ethers } from "ethers"

export const currentCycleStartTime = async () => {
  const contract = connectContract()

  return await contract.currentCycleStartTime()
}

export const nextCycleStartTime = async () => {
  const contract = connectContract()

  return await contract.nextCycleStartTime()
}

export const cycleDurationSeconds = async () => {
  const contract = connectContract()

  return await contract.cycleDurationSeconds()
}

export const totalAllocation = async () => {
  const contract = connectContract()

  return await contract.totalAllocation()
}

export const usersAllocation = async (address) => {
  const contract = connectContract()

  return await contract.usersAllocation(address)
}

export const allocate = async (account, amount, provider) => {
  const contract = connectContract(provider.getSigner())

  const parsedAmount = ethers.parseEther(amount)

  return await contract.allocate(account, parsedAmount, "0x")
}

export const deallocate = async (account, amount, provider) => {
  const contract = connectContract(provider.getSigner())

  const parsedAmount = ethers.parseEther(amount)

  return await contract.deallocate(account, parsedAmount, "0x")
}

export const harvestDividends = async (token, provider) => {
  const contract = connectContract(provider.getSigner())

  return await contract.harvestDividends(token)
}

export const pendingDividendsAmount = async (token, account) => {
  const contract = connectContract()

  return await contract.pendingDividendsAmount(token, account)
}

export const dividendsInfo = async (token) => {
  const contract = connectContract()

  return await contract.dividendsInfo(token)
}

const connectContract = (provider = providerReadOnly) => {
  return new Contract(DIVIDENDS_ADDRESS, DIVIDENDS_ABI, provider)
}
