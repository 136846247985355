import { useWeb3React } from "@web3-react/core"
import { Dividends } from "./dividends/dividends"
import { Redeem } from "./redeem/redeem"
import { XswordInfo } from "./xSwordInfo"
import { useEffect, useState } from "react"
import { allowance as allowanceX, balanceOf as balanceOfX } from "../../API/xtoken"
import { allowance2, balanceOf } from "../../API/erc20"
import {
  totalAllocation,
  usersAllocation,
  dividendsInfo as getCurrentInfo,
  nextCycleStartTime,
} from "../../API/dividends"
import { ethers } from "ethers"
import { DIVIDENDS_ADDRESS, SWORD_ADDRESS, SWORD_STAKE, WETH_ADDRESS, XSWORD_ADDRESS } from "../../constants"
import { getUserRedeem, getUserRedeemLength } from "../../API/swordStake"
import { useStore } from "@nanostores/react"
import { updateInizializeReedem, xStakeUpdate } from "../../state/state"
import { calculateApy } from "./dividends/dividendsUtils"
import { getETHPrice, convertSwordToUsd } from "../farming/farmingUtility"

export const Xsword = () => {
  const { account, active } = useWeb3React()

  const update = useStore(xStakeUpdate)
  const updateBal = useStore(updateInizializeReedem)

  const [userReedems, setUserReedems] = useState([])
  const [xSwordBalance, setXSwordBalance] = useState({ parsed: 0 })
  const [swordBalance, setSwordBalance] = useState({ parsed: 0 })
  const [redeemingXsword, setRedeemingXsword] = useState(0)

  //eslint-disable-next-line
  const [dividendsInfo, setDividendsInfo] = useState({
    totalAllocation: 0,
    startTime: null,
    xSwordRewards: null,
    apy: 0,
  })
  const [userDividendsInfo, setUserDividendsInfo] = useState({ allowance: 0, allocation: 0 })

  const updateBalance = async () => {
    const valueXBalance = await balanceOfX(account)
    const parsedX = ethers.formatEther(valueXBalance + "")

    let allowanceXValue = await allowanceX(account, SWORD_STAKE)
    allowanceXValue = ethers.formatEther(allowanceXValue + "")

    setXSwordBalance({ parsed: parsedX, bigNumber: valueXBalance, allowanceValue: allowanceXValue })

    const valueBalance = await balanceOf(account, SWORD_ADDRESS)
    const parsed = ethers.formatEther(valueBalance + "")

    let allowanceValue = await allowance2(account, SWORD_STAKE, SWORD_ADDRESS)
    allowanceValue = ethers.formatEther(allowanceValue + "")

    setSwordBalance({ parsed: parsed, bigNumber: valueBalance, allowanceValue: allowanceValue })
  }

  const getDividendsInfo = async () => {
    let allocation = await totalAllocation()
    allocation = ethers.formatEther(allocation + "")

    let startTime = await nextCycleStartTime()
    startTime = startTime + ""

    const currentEpochInfoXSword = await getCurrentInfo(XSWORD_ADDRESS)
    const xSwordRewards = ethers.formatEther(currentEpochInfoXSword[0] + "")
    const SwordUsdValue = await convertSwordToUsd(1)
    //eslint-disable-next-line
    const currentEpochInfoWETH = await getCurrentInfo(WETH_ADDRESS)
    const WETHRewards = ethers.formatEther(currentEpochInfoWETH[0] + "")
    const ETHPrice = await getETHPrice()
    const WETHUsdValue = WETHRewards * ETHPrice
    const WETHConvertedToSword = WETHUsdValue / SwordUsdValue

    const currentEpochInfoUSDC = await getCurrentInfo("0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4")
    const USDCRewards = ethers.formatUnits(currentEpochInfoUSDC[0] + "", 6)
    const USDCConvertedToSword = USDCRewards / SwordUsdValue

    const apy = calculateApy(allocation, +xSwordRewards + +WETHConvertedToSword + +USDCConvertedToSword)

    setDividendsInfo({ totalAllocation: allocation, startTime, apy })
  }

  const getDividendsUserInfo = async () => {
    let dividendsAllowance = await allowanceX(account, DIVIDENDS_ADDRESS)
    dividendsAllowance = ethers.formatEther(dividendsAllowance + "")

    let userAllocation = await usersAllocation(account)
    userAllocation = ethers.formatEther(userAllocation + "")

    setUserDividendsInfo({ allowance: dividendsAllowance, allocation: userAllocation })
  }

  const getUserRedeemInfo = async () => {
    getUserRedeemLength(account).then(async (value) => {
      const reedems = []
      let allLocked = 0
      for (let i = 0; i < value; i++) {
        const reedem = await getUserRedeem(account, i)
        const valueLocked = ethers.formatEther(reedem[0] + "")
        allLocked += +valueLocked
        reedems.push(reedem)
      }
      setRedeemingXsword(allLocked)
      setUserReedems(reedems)
    })
  }

  useEffect(() => {
    getDividendsInfo()

    if (!active) {
      setUserReedems([])
      setXSwordBalance({ parsed: 0, bigNumber: null })
      setSwordBalance({ parsed: 0, bigNumber: null })
      setRedeemingXsword(0)
      setUserDividendsInfo({ allowance: 0, allocation: 0 })
      return
    }

    if (active) {
      updateBalance()
      getDividendsUserInfo()
      getUserRedeemInfo()
    }
  }, [active, account, update])

  useEffect(() => {
    if (active) {
      updateBalance()
      getDividendsUserInfo()
    }
  }, [updateBal])

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center pb-4 ">
        <div className="text-white text-center font-semibold pb-8 pt-4 max-md:pt-14">
          <p className="text-4xl pb-3">
            <span className="text-orange-color">xSWORD</span> Dashboard
          </p>
          <p className="text-lg ">
            Lock/Redeem your <span className="text-orange-color">SWORD</span> and manage your{" "}
            <span className="text-orange-color">xSWORD</span> Dividends.
          </p>
        </div>
        <div className="md:w-8/12 w-full px-4 md:px-0 flex flex-col gap-4">
          <XswordInfo
            redeemingXsword={redeemingXsword}
            xSwordBalance={xSwordBalance}
            xSwordAllocated={userDividendsInfo.allocation}
          />
          <div className="md:grid md:grid-cols-2 max-md:flex max-md:flex-col-reverse gap-4 w-full">
            <Redeem
              userReedems={userReedems}
              xSwordBalance={xSwordBalance}
              setXSwordBalance={setXSwordBalance}
              swordBalance={swordBalance}
            />
            <Dividends
              dividendsInfo={dividendsInfo}
              userDividendsInfo={userDividendsInfo}
              xSwordBalance={xSwordBalance}
            />
          </div>
        </div>
      </div>
    </>
  )
}
