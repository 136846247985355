import { useState } from "react"
import { InteractionCard } from "./interactionCard"
import { StakeComponent } from "./stake"
import { UnstakeComponent } from "../../farming/modal/unstake"
import { ClaimComponent } from "./claim"

export const ManageFarm = ({ poolData, userData, index }) => {
  const [selected, setSelected] = useState("stake")
  return (
    <InteractionCard>
      <div className=" w-full h-full">
        <div className="w-full flex flex-col items-center justify-center">
          <div className="font-semibold text-white">
            <button
              onClick={() => {
                setSelected("claim")
              }}
              className={`rounded-bl-md ${
                selected === "claim" ? "bg-orange-color" : "bg-custom-color-solid "
              } py-3 px-4`}
            >
              Claim
            </button>
            <button
              onClick={() => {
                setSelected("stake")
              }}
              className={`${selected === "stake" ? "bg-orange-color" : "bg-custom-color-solid "} py-3 px-4`}
            >
              Stake
            </button>
            <button
              onClick={() => {
                setSelected("unstake")
              }}
              className={`rounded-br-md ${
                selected === "unstake" ? "bg-orange-color" : "bg-custom-color-solid "
              } py-3 px-4`}
            >
              Unstake
            </button>
          </div>
          <div className="w-full md:w-[70%] xl:w-[50%]  pb-4">
            {selected === "stake" ? (
              <StakeComponent poolData={poolData} userData={userData} index={index} />
            ) : selected === "unstake" ? (
              <UnstakeComponent poolData={poolData} userData={userData} index={index} />
            ) : (
              <ClaimComponent index={index} userData={userData} />
            )}
          </div>
        </div>
      </div>
    </InteractionCard>
  )
}
