import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Layout } from "./components/layout/layout"
import { Liquidity } from "./components/liquidity/Liquidity"
import { Swap } from "./components/swap/Swap"
import { Launchpad } from "./components/launchpad/pad/launchpad"
import { Launch } from "./components/launchpad/launch"
import { PRIVATE_ICO_ADDRESS, PUBLIC_ICO_ADDRESS, ZKDX_ICO } from "./constants"
import {
  launchZkdx,
  privateLaunch,
  publicLaunch,
  zkdxDescription,
  zkdxUrlData,
} from "./components/launchpad/pad/launchInfo"
import { Farming } from "./components/newFarming/farming"
import { Xsword } from "./components/new-xsword/newXsword"
import zkxdLogo from "./components/images/collab/zkDX.leaf.png"
import zkdxMainLogo from "./components/images/collab/zkdx-logo.png"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Swap />} />
          <Route path="/liquidity" element={<Liquidity />} />
          <Route path="/farms" element={<Farming />} />
          <Route path="/xsword" element={<Xsword />} />
          <Route path="/launchpad" element={<Launchpad />} />
          <Route
            path="/public-launch"
            element={<Launch icoAddress={PUBLIC_ICO_ADDRESS} info={publicLaunch} />}
          />
          <Route
            path="/private-launch"
            element={<Launch icoAddress={PRIVATE_ICO_ADDRESS} info={privateLaunch} />}
          />
          <Route
            path="/launch-zkdx"
            element={
              <Launch
                icoAddress={ZKDX_ICO}
                info={launchZkdx}
                logo={zkxdLogo}
                mainLogo={zkdxMainLogo}
                urlData={zkdxUrlData}
                description={zkdxDescription}
              />
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
