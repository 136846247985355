import { DividendsGeneralInfo } from "./dividendsGeneralInfo"
import { DividendsUserInfo } from "./dividendsUserInfo"

export const Dividends = ({ dividendsInfo, userDividendsInfo, xSwordBalance }) => {
  return (
    <>
      <div className="bg-custom-color py-4 rounded-md border-2 border border-card-border w-full h-full text-center text-white font-semibold flex flex-col">
        <p className="text-2xl pb-2">Dividends</p>
        <p className="px-4 pb-2">
          Earn real yield from protocol earnings by staking your{" "}
          <span className="text-orange-color">xSWORD</span> here.
        </p>
        <DividendsGeneralInfo dividendsInfo={dividendsInfo} />
        <DividendsUserInfo
          userDividendsInfo={userDividendsInfo}
          totalAllocation={dividendsInfo.totalAllocation}
          xSwordBalance={xSwordBalance}
        />
      </div>
    </>
  )
}
