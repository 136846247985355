import { useWeb3React } from "@web3-react/core"
import { Header } from "./header"
import { useEffect, useState } from "react"
import {
  claim,
  claim2,
  claim3,
  commitments,
  endTime,
  finalEmissions,
  finalTokens,
  receiveTime,
  simulateCommit,
  vestingClaim,
} from "../../../API/ico"
import { ethers } from "ethers"
import { DisabledButton } from "./disabledButton"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
import { displayDecimals } from "../../../utils"
import { ZKDX_ICO } from "../../../constants"

//eslint-disable-next-line
export const Claim = ({ icoAddress, info, isOnWhite }) => {
  const [isReceiveTime, setIsReceiveTime] = useState(false)
  const [isEndTime, setIsEndTime] = useState(false)
  const [isVesting, setVestingTime] = useState(false)
  const [data, setData] = useState({
    commit: "0.00",
    sword: "0.00",
    xSword: "0.00",
    earnedETH: "0.00",
    refound: "0.00",
    finales: "0.00",
    vesting: "0.00",
  })
  const { active, account, library } = useWeb3React()

  const isZKDXIco = () => icoAddress === ZKDX_ICO

  const correctAddress = () => (isZKDXIco() ? "0x5B5B3dccdd323D6475b29C3e0b4e3fd9fEB96DF0" : icoAddress)

  const getData = async () => {
    let commit = await commitments(icoAddress, account)
    commit = ethers.formatEther(commit + "")
    commit = Number(commit).toFixed(2)

    let finals = await finalEmissions(icoAddress, account)
    finals = ethers.formatEther(finals + "")

    const correctVestingAddress = correctAddress()

    let vesting = await vestingClaim(correctVestingAddress, isZKDXIco() ? account : undefined, library)
    vesting = ethers.formatEther(vesting + "")
    vesting = displayDecimals(vesting)

    if (info.calculateClaim) {
      let finalsToken = await finalTokens(icoAddress, account)
      finalsToken = ethers.formatEther(finalsToken + "")
      finalsToken = (finalsToken * 40) / 100
      finalsToken = Number(finalsToken).toFixed(2)

      setData({
        commit,
        sword: finalsToken,
        xsword: 0,
        earnedETH: Number(finals).toFixed(2),
        refound: 0,
        finals,
        vesting,
      })
      return
    } else {
      let simulate = await simulateCommit(icoAddress, library)
      let refound = simulate[0]
      refound = ethers.formatEther(refound + "")
      refound = Number(refound).toFixed(2)
      let sword = simulate[1]
      sword = ethers.formatEther(sword + "")
      sword = Number(sword).toFixed(2)
      let xSword = simulate[2]
      xSword = ethers.formatEther(xSword + "")
      xSword = Number(xSword).toFixed(2)
      let earnedETH = simulate[3]
      earnedETH = ethers.formatEther(earnedETH + "")
      earnedETH = Number(earnedETH).toFixed(2)
      setData({ commit, sword, xSword, earnedETH, refound, finals, vesting })
    }
  }

  const getReceiveTime = async () => {
    let receiveDate = await receiveTime(icoAddress)
    receiveDate = new Date((receiveDate + "") * 1000)
    setIsReceiveTime(Date.now() > receiveDate)
  }

  const getVestingTime = async () => {
    if (info.vestingStart) {
      setVestingTime(Date.now() > info.vestingStart)
    }
  }

  const getEndTime = async () => {
    let endDate = await endTime(icoAddress)
    endDate = new Date((endDate + "") * 1000)
    setIsEndTime(endDate < Date.now())
  }

  const execute = () => {
    claim(icoAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              getData()
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch(() => console.error("Transaction failed"))
  }

  const execute2 = () => {
    claim2(icoAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              getData()
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch(() => console.error("Transaction failed"))
  }

  const execute3 = () => {
    claim3(correctAddress(), account, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              getData()
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch(() => console.error("Transaction failed"))
  }

  useEffect(() => {
    getReceiveTime()
    getEndTime()
    getVestingTime()
  }, [])

  useEffect(() => {
    if (!library) return

    getData()
  }, [active, account])

  return (
    <Header name={"CLAIM"}>
      {data.commit == 0 && data.sword > 0 ? (
        ""
      ) : (
        <div className="flex flex-row py-2 md:gap-20 gap-12">
          <div className="flex flex-col">
            <p className="text-gray-500 ">Total Purchased</p>
            <p className="text-orange-color">{data.commit} ETH</p>
          </div>
          <div className="flex flex-col">
            <p className="text-gray-500">ETH to Refund</p>
            <p className="text-white">{data.refound} ETH</p>
          </div>
        </div>
      )}
      <div className="border-t w-10/12 self-center border-card-border" />
      <p className="text-gray-500 py-2">Claimable</p>

      <div className="flex flex-row md:gap-20 gap-12">
        <p className="text-white pb-2">
          {data.sword} {info.symbol}
        </p>
        {info.symbol1 ? (
          <p className="text-white pb-2">
            {data.xSword} {info.symbol1}
          </p>
        ) : (
          ""
        )}
      </div>
      <div className="border-t w-10/12 self-center border-card-border" />
      <div className="flex flex-row md:gap-20 gap-12 py-2">
        <div className="flex flex-col">
          <p className="text-gray-500">Earned Farming Bonuses</p>
          <p className="text-orange-color">
            {data.earnedETH} {info.farmingSymbol}
          </p>
        </div>
      </div>
      {!active ? (
        <DisabledButton text={"Connect wallet first!"} />
      ) : !isOnWhite ? (
        <DisabledButton text={"Not whitelisted"} />
      ) : !isEndTime ? (
        <DisabledButton text={"IDO not ended"} />
      ) : (data.sword == 0 && !info.calculateClaim) ||
        (info.calculateClaim && +data.commit == 0.0 && data.sword == 0) ? (
        <DisabledButton text={"Nothing to claim"} />
      ) : data.finals == 0 ? (
        <button onClick={() => execute()} className="my-2 w-10/12 h-16 bg-orange-color rounded-md">
          {!info.calculateClaim ? "CLAIM" : "CALCULATE CLAIM"}
        </button>
      ) : !isReceiveTime ? (
        <DisabledButton text={"Receive Time not started!"} />
      ) : (
        <button onClick={() => execute2()} className="my-2 w-10/12 h-16 bg-orange-color rounded-md">
          CLAIM
        </button>
      )}

      {info.vesting ? (
        !active ? (
          <DisabledButton text={"Connect wallet first!"} />
        ) : !isOnWhite ? (
          <DisabledButton text={"Not whitelisted"} />
        ) : !isVesting ? (
          <DisabledButton text={"Vesting not started"} />
        ) : data.vesting == 0 ? (
          <DisabledButton text={"Nothing to claim on vesting"} />
        ) : (
          <button onClick={() => execute3()} className="my-2 w-10/12 h-16 bg-orange-color rounded-md">
            {data.vesting} {`${info.symbol} from vesting`}
          </button>
        )
      ) : (
        ""
      )}
    </Header>
  )
}
