import { Outlet, useLocation } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./style.css"
import { useEffect, useState } from "react"
import swap from "../images/background/swap.png"
import liquidity from "../images/background/liquidity.png"
import generic from "../images/background/bg1.1.png"
import startup from "../images/startup.mp4"
import ReactPlayer from "react-player"
import { Nav } from "../navbar/navbar"
import { useWeb3React } from "@web3-react/core"
import { connections } from "../connection/connector"

export const Layout = () => {
  const { activate } = useWeb3React()
  const location = useLocation()
  const [backgroundImage, setBackgroundImage] = useState(swap)
  const [showVideo, setShowVideo] = useState(true)
  const isMobile = window.innerWidth <= 768 // Imposta la larghezza massima per dispositivi mobili

  useEffect(() => {
    let connectionInfo = localStorage.getItem("connection")

    if (connectionInfo) {
      connectionInfo = JSON.parse(connectionInfo)

      connections.forEach((connection) => {
        if (connectionInfo.name === connection.name) {
          try {
            activate(connection.connector)
          } catch (error) {
            console.log(error)
          }
        }
      })
    }
  })

  useEffect(() => {
    if (location.pathname === "/") {
      setBackgroundImage(swap)
    } else if (location.pathname === "/liquidity") {
      setBackgroundImage(liquidity)
    } else {
      setBackgroundImage(generic)
    }
  }, [location])

  useEffect(() => {
    const hasViewedVideo = sessionStorage.getItem("hasViewedVideo")

    if (hasViewedVideo) {
      setShowVideo(false)
    } else {
      setShowVideo(true)
    }
  }, [])

  const handleVideoReady = () => {
    setShowVideo(true)
    setTimeout(() => {
      setShowVideo(false)
      sessionStorage.setItem("hasViewedVideo", "true")
    }, 5000)
  }

  return (
    <>
      {showVideo && !isMobile ? ( // Aggiunge la condizione per verificare se non si tratta di un dispositivo mobile
        <div className="video-overlay h-screen w-screen bg-black flex items-center overflow-hidden">
          <ReactPlayer
            url={startup}
            playing
            muted
            width="100vw"
            height="100vh"
            className="video-background"
            onReady={handleVideoReady}
          />
        </div>
      ) : (
        <>
          <div className="top-0 left-0 fixed -z-50 h-screen w-screen bg-black">
            <img className="w-screen h-screen" src={backgroundImage} alt="Background" />
          </div>
          <div className="z-50">
            <Nav />
            <div id="main-layout" className="h-[calc(100vh-63px)]">
              <Outlet />
            </div>
            <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </div>
        </>
      )}
    </>
  )
}
