import { useState, useEffect } from "react"
import { toast } from "react-toastify"

//eslint-disable-next-line
export const SettingLiquidity = ({ setIseSettingOpen, isSettingOpen, setSetting, setting }) => {
  const [selectedSetting, setSelectedSetting] = useState({
    slippage: setting[isSettingOpen.type].slippage,
    deadLine: setting[isSettingOpen.type].deadLine,
  })

  const selected = "w-14 h-8 rounded-md flex justify-center items-center bg-orange-color"
  const notSelected = "w-14 h-8 rounded-md flex justify-center items-center bg-button-color"

  const handleSet = (e) => {
    setSelectedSetting({ ...selectedSetting, [e.target.name]: e.target.value })
    if (e.target.id !== "input-slippage") {
      document.getElementById("input-slippage").value = ""
    }
  }

  const saveSetting = () => {
    let temp = setting
    temp[isSettingOpen.type] = selectedSetting

    setSetting(temp)
    toast("Setting saved")
  }

  useEffect(() => {
    if (selectedSetting.slippage === "") {
      setSelectedSetting({ ...selectedSetting, slippage: "0.5" })
    }

    if (selectedSetting.deadLine === "") {
      setSelectedSetting({ ...selectedSetting, deadLine: "90" })
    }

    if (
      selectedSetting.slippage !== "0.5" &&
      selectedSetting.slippage !== "1" &&
      selectedSetting.slippage !== "3"
    )
      document.getElementById("input-slippage").value = selectedSetting.slippage
  }, [selectedSetting])

  const firstUpper = (data) => {
    const firstChar = data.charAt(0)

    return firstChar.toUpperCase() + data.slice(1)
  }

  return (
    <>
      <div className="flex items-center">
        <h5 className="text-white text-lg font-semibold">{firstUpper(isSettingOpen.type)} Settings</h5>
      </div>
      <div className="flex flex-col">
        <h5 className="font-semibold self-start text-white pt-2">Slippage Tolerance</h5>
        <div className="text-white flex gap-1 text-sm items-center pt-1.5">
          <button
            id="slippage"
            name="slippage"
            value="0.5"
            onClick={(e) => handleSet(e)}
            className={selectedSetting.slippage === "0.5" ? selected : notSelected}
          >
            0.5%
          </button>
          <button
            id="slippage"
            name="slippage"
            value="1"
            onClick={(e) => handleSet(e)}
            className={selectedSetting.slippage === "1" ? selected : notSelected}
          >
            1%
          </button>
          <button
            id="slippage"
            name="slippage"
            value="3"
            onClick={(e) => handleSet(e)}
            className={selectedSetting.slippage === "3" ? selected : notSelected}
          >
            3%
          </button>
          <input
            id="input-slippage"
            name="slippage"
            type="number"
            onChange={handleSet}
            className="border-2 border-card-border bg-transparent w-14 h-8 rounded-md border-2 flex justify-center items-center font-semibold pr-2.5 text-right"
          />
          <p>%</p>
        </div>
        <p className={`text-white font-bold pt-3 ${selectedSetting.slippage === "0" ? "" : "hidden"}`}>
          Slippage can be 0
        </p>
      </div>
      <div className="text-white font-bold pt-3 flex flex-col">
        <h5 className="font-semibold text-white self-start">Transaction Deadline</h5>
        <div className="flex gap-1.5 pt-1.5">
          <input
            type="number"
            id="deadLine"
            name="deadLine"
            onChange={handleSet}
            placeholder={selectedSetting.deadLine}
            className="border-2 text-right border-card-border bg-transparent w-24 h-8 rounded-md border-2 flex justify-center items-center font-semibold pr-2.5"
          />
          <p className="text-lg">Min</p>
        </div>
      </div>
      <div>
        <button
          onClick={saveSetting}
          className="pt-2 pb-3 w-full rounded-md border-2 tex-lg font-bold text-orange-color mt-4 border-2 border-orange-color"
        >
          Save Settings
        </button>
      </div>
    </>
  )
}
