import { useWeb3React } from "@web3-react/core"
import leaf from "../../../images/leaf.png"
import { useState } from "react"

import { ethers } from "ethers"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../../toast/receiptToast"
import { updateInizializeReedem, xStakeUpdate } from "../../../../state/state"
import { SWORD_STAKE, XSWORD_ADDRESS } from "../../../../constants"
import { approve2 } from "../../../../API/xtoken"
import { doInitializeReedem } from "../../../../API/swordStake"
import { DisabledButton } from "../../../utils/disabledButton"

//eslint-disable-next-line
export const InizializeReedem = ({ balance, setBalance }) => {
  const { active, library } = useWeb3React()
  const [data, setData] = useState({ amount: null, days: null })
  const [ratio, setRatio] = useState(0)

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (!value) {
      if (name === "days") {
        let change = data
        change[name] = 0
        setData({ ...data, ...change })
        setRatio(ratio)
      }
      return
    }

    if (name === "days") {
      let ratio = 50 + ((value - 15) * 2) / 3
      setRatio(parseInt(ratio))
    }

    let change = data
    change[name] = value
    setData({ ...data, ...change })
  }

  const setMax = () => {
    setData({ ...data, amount: balance.parsed })
  }

  const setDays = (value) => {
    let ratio = 50 + ((value - 15) * 2) / 3
    setRatio(parseInt(ratio))
    setData({ ...data, days: value })
  }

  const executeApprove = async () => {
    approve2(SWORD_STAKE, ethers.parseEther(data.amount) + "", library, XSWORD_ADDRESS)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  const execute = async () => {
    doInitializeReedem(ethers.parseEther(data.amount) + "", data.days, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
              xStakeUpdate.set(xStakeUpdate.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  return (
    <div className="md:w-[500px] h-fit bg-custom-color-solid rounded-md border-2 border-card-border flex flex-col items-center py-1 md:py-6 px-4 text-white font-semibold">
      <p className="text-xl">Redeem Sword</p>
      <p className="py-2 text-gray-500">Burn your xSWORD to redeem back SWORD.</p>
      <p className="text-gray-500">
        Choose a vesting period between 15 days (0.5:1 Ratio) - 90 days (1:1 Ratio) and wait to claim back
        your SWORD.
      </p>
      <p className="text-gray-500">
        {/* eslint-disable-next-line */}
        Keep in mind that without xSWORD you won't be able to get Launchpad benefits and you won't be able to
        earn Dividends.
      </p>
      <button onClick={setMax} className="self-end text-gray-500 pb-2">
        Available: {Math.floor(Number(balance.parsed) * 100) / 100}
      </button>
      <div className="w-full h-[50px] border-card-border rounded-md border-2 px-4 flex items-center text-white">
        <p className="flex flex-row items-center">
          <img src={leaf} width={20} className="mr-2" /> xSWORD
        </p>
        <input
          onChange={handleInputChange}
          name="amount"
          type="number"
          className="w-10/12 bg-transparent text-end"
          placeholder="0"
          value={data.amount}
        />
      </div>
      <p className="self-end text-gray-500 py-2">
        Min <button onClick={() => setDays("15")}>15</button> - Max{" "}
        <button onClick={() => setDays("90")}>90</button>
      </p>
      <div className="w-full h-[50px] border-card-border rounded-md border-2 px-4 flex justify-between items-center text-white">
        <p className="flex flex-row items-center"> Days</p>
        <input
          onChange={handleInputChange}
          name="days"
          id="days"
          type="number"
          min="15"
          max="90"
          className="w-10/12 bg-transparent text-end"
          placeholder="0"
          value={data.days}
        />
      </div>
      <div className="border-t w-full my-4 self-center border-card-border" />
      <div className="w-full flex gap-6 flex-col px-2">
        <div className="w-full flex justify-between">
          <p>Ratio</p>
          <p>
            {ratio === 0 || data.days == 0 || data.days < 15 || data.days > 90
              ? "0.5:1"
              : (ratio / 100).toFixed(2)}
          </p>
        </div>
        <div className="w-full flex justify-between">
          <p>Penality</p>
          <p>
            {ratio === 0 || data.days == 0 || data.days < 15 || data.days > 90
              ? "100"
              : Math.round((100 - ratio) * 100) / 100}
            %
          </p>
        </div>
        <div className="w-full flex justify-between">
          <p>Redeem</p>
          <p>
            <span className="text-orange-color">
              +
              {data.days == 0 || data.days < 15 || data.days > 90
                ? "0"
                : Math.round((data.amount - (data.amount * (100 - ratio)) / 100) * 100) / 100}
            </span>{" "}
            SWORD
          </p>
        </div>
      </div>
      {data.days < 15 || data.days > 90 ? (
        <DisabledButton text={"Days not valid"} />
      ) : !active ? (
        <DisabledButton text={"Connect wallet First!"} />
      ) : +data.amount > +balance.parsed ? (
        <DisabledButton text={"Amount exceed Balance!"} />
      ) : +data.amount > +balance.allowanceValue ? (
        <button onClick={executeApprove} className="mt-6 px-4 w-full h-[46px] bg-orange-color rounded-md">
          Approve
        </button>
      ) : (
        <button onClick={execute} className="mt-6 px-4 w-full h-[46px] bg-orange-color rounded-md">
          Initialize Redeem
        </button>
      )}
    </div>
  )
}
