import { UserAllocation } from "./userAllocation"
import { UserPendingRewards } from "./userPendingRewards"

export const DividendsUserInfo = ({ userDividendsInfo, totalAllocation, xSwordBalance }) => {
  return (
    <>
      <div className="flex flex-col w-full pt-4">
        <p className="text-2xl">Your Allocation</p>
        <div className="border-t border-card-border w-11/12 my-4 self-center" />
        <UserAllocation
          userDividendsInfo={userDividendsInfo}
          totalAllocation={totalAllocation}
          xSwordBalance={xSwordBalance}
        />
        <UserPendingRewards />
      </div>
    </>
  )
}
