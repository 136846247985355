import leaf from "../../../images/leaf.png"
import { removeDecimals } from "../../../../utils"
import { DisabledButton } from "../../../utils/disabledButton"
import { useWeb3React } from "@web3-react/core"
import { approve2 } from "../../../../API/xtoken"
import { DIVIDENDS_ADDRESS } from "../../../../constants"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../../toast/receiptToast"
import { updateInizializeReedem, xStakeUpdate } from "../../../../state/state"
import { useState } from "react"
import { ethers } from "ethers"
import { allocate } from "../../../../API/dividends"

export const Allocate = ({ userDividendsInfo, xSwordBalance }) => {
  const { account, active, library } = useWeb3React()
  const [amount, setAmount] = useState(null)

  const handleInputChange = (e) => {
    const value = e.target.value
    setAmount(value)
  }

  const setMax = () => {
    setAmount(xSwordBalance.parsed)
  }

  const executeApprove = async () => {
    approve2(DIVIDENDS_ADDRESS, ethers.parseEther(amount), library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  const execute = async () => {
    allocate(account, amount, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
              xStakeUpdate.set(xStakeUpdate.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  return (
    <div className="md:w-[500px] h-fit bg-custom-color-solid rounded-md border-2 border-card-border flex flex-col items-center py-6 px-4 text-white font-semibold">
      <p className="text-xl">Allocate xSWORD</p>
      <p className="text-gray-500 pt-2">
        Allocate xSWORD to earn a share of protocol earnings in the form of real yield.
      </p>
      <button onClick={setMax} className="self-end text-gray-500 pb-2">
        Available: {removeDecimals(xSwordBalance.parsed)}
      </button>
      <div className="w-full h-[50px] border-card-border rounded-md border-2 px-4 flex items-center text-white">
        <p className="flex flex-row items-center">
          <img src={leaf} width={20} className="mr-2" /> xSWORD
        </p>
        <input
          onChange={handleInputChange}
          name="amount"
          type="number"
          className="w-10/12 bg-transparent text-end"
          placeholder="0"
          value={amount}
        />
      </div>
      {!active ? (
        <DisabledButton text={"Connect wallet First!"} />
      ) : +amount > +xSwordBalance.parsed ? (
        <DisabledButton text={"Amount exceed Balance!"} />
      ) : +amount > +userDividendsInfo.allowance ? (
        <button onClick={executeApprove} className="mt-6 px-4 w-full h-[46px] bg-orange-color rounded-md">
          Approve
        </button>
      ) : (
        <button onClick={execute} className="mt-6 px-4 w-full h-[46px] bg-orange-color rounded-md">
          Allocate
        </button>
      )}
    </div>
  )
}
