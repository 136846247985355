import { useState } from "react"
import { unStakes } from "../../../API/farming"
import { DisabledButton } from "../../utils/disabledButton"
import { stakeUpdate } from "../../../state/state"
import { ethers } from "ethers"
import { useWeb3React } from "@web3-react/core"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"

export const UnstakeComponent = ({ index, poolData, userData }) => {
  const { library, active } = useWeb3React()
  const [haveToken, setHaveToken] = useState(true)
  const [selectedBtn, setSelectedBtn] = useState(0)

  const [inputValue, setInputValue] = useState()

  const execute = () => {
    unStakes(index, ethers.parseEther(inputValue) + "", library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              stakeUpdate.set({ index, date: Date.now() })
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  const checkBalance = (value) => {
    setHaveToken(+userData.amountStaked >= +value)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setSelectedBtn(0)
    setInputValue(value)
    checkBalance(value)
  }

  const preCompile = (value) => {
    setSelectedBtn(value)
    if (userData.amountStaked == 0) {
      setInputValue(0)
      return
    }

    const perc = value == 100 ? userData.amountStaked : (userData.amountStaked * value) / 100

    setInputValue(perc.toString().replace(",", "."))
    checkBalance(perc)
  }

  return (
    <div className="container w-full text-gray-500 font-semibold flex flex-col items-center">
      <div className="flex flex-col w-full text-center px-4 pt-4 pb-12">
        <p className="text-white">First, you neet to deposit your tokens into the liquidity pools.</p>
        <p>Then, use the returned LP Tokens and stake them into the respective farm on this page</p>
      </div>
      <div className="flex pb-1 flex-row w-11/12 justify-center">
        <p>Available: {userData.amountStaked}</p>
      </div>

      <div className="w-11/12">
        <div className="w-full h-[50px] border-gray-500 rounded-md border-2 px-4 flex flex-row justify-between items-center text-white">
          {poolData.symbol0}-{poolData.symbol1} LP
          <input
            onChange={handleInputChange}
            type="number"
            className="w-[70%] bg-transparent text-end"
            placeholder="0"
            value={inputValue}
          />
        </div>
      </div>
      <div className="w-11/12">
        <div className="flex md:flex-wrap justify-center self-start text-white gap-2 pt-3">
          <button
            onClick={() => preCompile(25)}
            className={`${
              selectedBtn === 25 ? "bg-orange-color" : "bg-gray-500"
            } py-2 px-2 md:px-5 rounded-md`}
          >
            25%
          </button>
          <button
            onClick={() => preCompile(50)}
            className={`${
              selectedBtn === 50 ? "bg-orange-color" : "bg-gray-500"
            } py-2 px-2 md:px-5 rounded-md`}
          >
            50%
          </button>
          <button
            onClick={() => preCompile(75)}
            className={`${
              selectedBtn === 75 ? "bg-orange-color" : "bg-gray-500"
            } py-2 px-2 md:px-5 rounded-md`}
          >
            75%
          </button>
          <button
            onClick={() => preCompile(100)}
            className={`${
              selectedBtn === 100 ? "bg-orange-color" : "bg-gray-500"
            } py-2 px-2 md:px-5 rounded-md`}
          >
            100%
          </button>
        </div>
      </div>
      <div className="w-11/12 pt-8">
        {!active ? (
          <DisabledButton text={"Connect Wallet First!"} />
        ) : inputValue == 0 ? (
          <DisabledButton text={"Insert amount First!"} />
        ) : !haveToken ? (
          <DisabledButton text={"Amount too hight"} />
        ) : userData.amountStaked === "0.0" ? (
          <DisabledButton text={"Nothing to unstake!"} />
        ) : (
          <div className="text-white">
            <button onClick={execute} className="my-2 px-4 w-full h-[46px] bg-orange-color rounded-md">
              Unstake
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
