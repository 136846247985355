import { useEffect, useState } from "react"
import { getPair } from "../../API/router"
import { addressSlice } from "../../utils"
import { getPairToken, getReserve, getTotalSupply, pairBalanceOf } from "../../API/pair"
import { useWeb3React } from "@web3-react/core"
import { ethers } from "ethers"
import { WETH_ADDRESS } from "../../constants"
import { parseWeiToValue } from "../../API/erc20"
import { MdContentCopy, MdOpenInNew } from "react-icons/md"
import { toast } from "react-toastify"
import { baseToken } from "../selectToken/baseToken"
import noSymbol from "../images/tokens/noSymbol.png"
import { Spinner } from "../spinner/Spinner"
//eslint-disable-next-line
export const PairSelection = ({ setIsSelectingToken, tokenSelection, pair, setPair }) => {
  const [internalPair, setInternalPair] = useState()
  const { account, active } = useWeb3React()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (tokenSelection.tokenA === tokenSelection.tokenB) {
      setLoading(false)
      return
    } else if (tokenSelection.tokenA && tokenSelection.tokenB) {
      setLoading(true)
      getPair(
        tokenSelection.tokenA === "ETH" ? WETH_ADDRESS : tokenSelection.tokenA,
        tokenSelection.tokenB === "ETH" ? WETH_ADDRESS : tokenSelection.tokenB
      ).then(async (pairAddress) => {
        if (!pairAddress) {
          setInternalPair({
            pairAddress: ethers.ZeroAddress,
            pairBalance: "0",
            token0: tokenSelection.tokenA,
            reserve0: "0",
            reserve1: "0",
          })
          setLoading(false)
          return
        }
        let pairBalance = "0.0"
        const reserves = await getReserve(pairAddress)
        const token0 = await getPairToken(0, pairAddress)
        const token1 = await getPairToken(1, pairAddress)
        const reserve0 = await parseWeiToValue(reserves[0] + "", token0)
        const reserve1 = await parseWeiToValue(reserves[1] + "", token1)
        const totalSupply = await getTotalSupply(pairAddress)
        if (active) {
          pairBalance = await pairBalanceOf(account, pairAddress)
        }
        const ratio = pairBalance / totalSupply
        const userReserve0 = reserve0 * ratio
        const userReserve1 = reserve1 * ratio
        setInternalPair({
          pairAddress,
          pairBalance,
          reserve0,
          reserve1,
          token0,
          token1,
          symbol0: tokenSelection.symbolA,
          symbol1: tokenSelection.symbolB,
          totalSupply,
          userReserve0,
          userReserve1,
        })
        setLoading(false)
      })
    }
  }, [])

  useEffect(() => {
    if (tokenSelection.tokenA && tokenSelection.tokenB && active && internalPair) {
      if (active && internalPair && internalPair.pairAddress !== ethers.ZeroAddress) {
        pairBalanceOf(account, internalPair.pairAddress).then((pairBalance) => {
          setInternalPair({ ...internalPair, pairBalance })
        })
      }
    }
  }, [account, active, internalPair])

  const openAddressOnScanner = () => {
    window.open(`https://explorer.zksync.io/address/${internalPair.pairAddress}`, "_blank", "noreferrer")
  }

  const copyAddressToClipBoard = () => {
    navigator.clipboard.writeText(internalPair.pairAddress)
    toast("Pair address copied on clipboard")
  }

  return (
    <div className=" px-4 rounded-md border-2 border-2 py-4 border-card-border bg-custom-color w-full text-white font-semibold">
      <p className="text-gray-500">Select a Pair</p>
      <div className="flex flex-row justify-between py-3">
        <button
          onClick={() => setIsSelectingToken({ a: true, b: false })}
          className="w-2/5 h-12 border-2 border-card-border rounded-md border-2 "
        >
          <div className="flex flex-row gap-2 items-center justify-center">
            <img
              className="w-5"
              src={baseToken.find((item) => item.address === tokenSelection.tokenA)?.img || noSymbol}
            />
            <p>{tokenSelection.symbolA}</p>
          </div>
        </button>
        <p className="text-3xl">+</p>
        <button
          onClick={() => setIsSelectingToken({ a: false, b: true })}
          className="w-2/5 h-12 border-2 border-card-border rounded-md border-2 "
        >
          <div className="flex flex-row gap-2 items-center justify-center">
            <img
              className="w-5"
              src={baseToken.find((item) => item.address === tokenSelection.tokenB)?.img || noSymbol}
            />
            <p>{tokenSelection.symbolB}</p>
          </div>
        </button>
      </div>
      {loading ? (
        <div className="flex flex-row justify-start items-center gap-2 py-8 px-8">
          <Spinner size={20} />
          <p className="self-start">Searching pair...</p>
        </div>
      ) : tokenSelection.tokenA === tokenSelection.tokenB ? (
        <div className="flex flex-row justify-between py-8 px-8">
          <p className="self-start">Select different token</p>
        </div>
      ) : internalPair ? (
        internalPair.pairAddress === ethers.ZeroAddress ? (
          <div className="flex flex-row justify-between py-8 px-8">
            <p className="self-start">No Pair Found</p>
          </div>
        ) : (
          <div className="flex flex-row gap-2 py-8 px-8">
            <p>{addressSlice(internalPair.pairAddress)}</p>
            <button onClick={openAddressOnScanner} className="">
              <MdOpenInNew size={"23"} />
            </button>
            <button onClick={copyAddressToClipBoard} className="">
              <MdContentCopy size={"23"} />
            </button>
          </div>
        )
      ) : (
        <div className="flex flex-row justify-between py-8 px-8">
          <p className="self-start">NO Token selected!</p>
        </div>
      )}
      <div className="flex flex-row justify-between">
        <p>Total Liquidity</p>
        <div className="pr-4 flex flex-col">
          <p className="self-end">
            {internalPair && internalPair.reserve1 ? Number(internalPair.reserve0).toFixed(2) : "0"}{" "}
            {internalPair && internalPair.reserve1
              ? tokenSelection.tokenA === internalPair.token0
                ? tokenSelection.symbolA
                : tokenSelection.symbolB
              : ""}
          </p>
          <p className="self-end">
            {internalPair && internalPair.reserve1 ? Number(internalPair.reserve1).toFixed(2) : "0"}{" "}
            {internalPair && internalPair.reserve1
              ? tokenSelection.tokenA !== internalPair.token0
                ? tokenSelection.symbolA
                : tokenSelection.symbolB
              : ""}
          </p>
        </div>
      </div>
      <button
        onClick={() => setPair(internalPair)}
        className="bg-orange-color mt-2 h-12 w-full rounded-md text-white"
      >
        {internalPair && internalPair.pairAddress === ethers.ZeroAddress ? "Create Pair" : "Manage Liquidity"}
      </button>
    </div>
  )
}
