import { useStore } from "@nanostores/react"
import { swapConfig } from "../../state/state"
import arrowLeft from "../images/arrowLeft.png"
import { useState, useEffect } from "react"
import { toast } from "react-toastify"
import { PairInfo } from "./PairInfo"

export const SwapSetting = ({ setOpenSetting, tokensDashboard, invertSwap }) => {
  var swapConf = useStore(swapConfig)
  const [selectedSetting, setSelectedSetting] = useState({
    slippage: swapConf.slippage,
    deadLine: swapConf.deadLine,
  })

  const selected = "w-14 h-8 rounded-md flex justify-center items-center bg-orange-color"
  const notSelected = "w-14 h-8 rounded-md flex justify-center items-center bg-button-color"

  const handleSet = (e) => {
    setSelectedSetting({ ...selectedSetting, [e.target.name]: e.target.value })
    if (e.target.id !== "input-slippage") {
      document.getElementById("input-slippage").value = ""
    }
  }

  const saveSetting = () => {
    swapConfig.set({ ...swapConf, ...selectedSetting })
    setOpenSetting(false)
    toast("Setting saved")
  }

  useEffect(() => {
    if (selectedSetting.slippage === "") {
      setSelectedSetting({ ...selectedSetting, slippage: "0.5" })
    }

    if (selectedSetting.deadLine === "") {
      setSelectedSetting({ ...selectedSetting, deadLine: "90" })
    }

    if (
      selectedSetting.slippage !== "0.5" &&
      selectedSetting.slippage !== "1" &&
      selectedSetting.slippage !== "3"
    )
      document.getElementById("input-slippage").value = selectedSetting.slippage
  }, [selectedSetting])

  return (
    <>
      <div className="flex flex-row w-full h-full gap-6">
        <div className="max-md:hidden">
          <PairInfo tokensDashboard={tokensDashboard} changeDashboard={invertSwap} />
        </div>
        <div className="flex flex-col basis-2/6 bg-custom-color p-5 rounded-md border-2 border-card-border">
          <div className="flex items-center">
            <img src={arrowLeft} onClick={() => setOpenSetting(false)} className="w-9 pointer" />{" "}
            <h5 className="text-white text-lg font-semibold ml-1">Swap Settings</h5>
          </div>
          <div>
            <h5 className="font-semibold text-white pt-2">Slippage Tolerance</h5>
            <div className="text-white flex gap-1 text-sm items-center pt-1.5">
              <button
                id="slippage"
                name="slippage"
                value="0.5"
                onClick={(e) => handleSet(e)}
                className={selectedSetting.slippage === "0.5" ? selected : notSelected}
              >
                0.5%
              </button>
              <button
                id="slippage"
                name="slippage"
                value="1"
                onClick={(e) => handleSet(e)}
                className={selectedSetting.slippage === "1" ? selected : notSelected}
              >
                1%
              </button>
              <button
                id="slippage"
                name="slippage"
                value="3"
                onClick={(e) => handleSet(e)}
                className={selectedSetting.slippage === "3" ? selected : notSelected}
              >
                3%
              </button>
              <input
                id="input-slippage"
                name="slippage"
                type="number"
                onChange={handleSet}
                className="border-card-border bg-transparent w-14 h-8 rounded-md border-2 flex justify-center items-center font-semibold pr-2.5 text-right"
              />
              <p>%</p>
            </div>
            <p className={`text-white font-bold pt-3 ${selectedSetting.slippage === "0" ? "" : "hidden"}`}>
              Slippage can be 0
            </p>
          </div>
          <div className="text-white font-bold pt-3">
            <h5 className="font-semibold text-white">Transaction Deadline</h5>
            <div className="flex gap-1.5 pt-1.5">
              <input
                type="number"
                id="deadLine"
                name="deadLine"
                onChange={handleSet}
                placeholder={selectedSetting.deadLine}
                className="border-2 text-right border-card-border bg-transparent w-24 h-8 rounded-md flex justify-center items-center font-semibold pr-2.5"
              />
              <p className="text-lg">Min</p>
            </div>
          </div>
          <div>
            <button
              onClick={saveSetting}
              className="pt-2 pb-3 w-full rounded-md tex-lg font-bold text-orange-color mt-4 border-2 border-orange-color"
            >
              Save Settings
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
