import PAIR_ABI from "../ABI/PAIR_ABI.json"
import { Contract } from "@ethersproject/contracts"
import { parseValueToWeiString } from "./erc20"
import { providerReadOnly } from "../state/state"
import { ethers } from "ethers"

export const getReserve = async (contractAddress, provider = providerReadOnly) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.getReserves()
}

export const getPairToken = async (tokenSelection, contractAddress, provider = providerReadOnly) => {
  const contract = connectContract(contractAddress, provider)
  if (tokenSelection === 0) return await contract.token0()
  else return await contract.token1()
}

export const getPairName = async (contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.name()
}

export const getSymbol = async (contractAddress, provider = providerReadOnly) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.symbol()
}

export const pairBalanceOf = async (account, contractAddress, provider = providerReadOnly) => {
  const contract = connectContract(contractAddress, provider)
  const balance = await contract.balanceOf(account)
  const parsedBalance = ethers.formatEther(balance + "")

  return parsedBalance
}

export const pairAllowance = async (owner, spender, contractAddress, provider = providerReadOnly) => {
  const contract = connectContract(contractAddress, provider)
  return await contract.allowance(owner, spender)
}

export const pairApprove = async (spender, amount, contractAddress, provider) => {
  const contract = connectContract(contractAddress, provider.getSigner())
  const parsedAmount = await parseValueToWeiString(amount, contractAddress, provider)

  return await contract.approve(spender, parsedAmount)
}

export const getAllowance = async (owner, spender, contractAddress) => {
  const contract = connectContract(contractAddress)

  const allowanceValue = await contract.allowance(owner, spender)

  return ethers.parseUnits(allowanceValue + "")
}

export const getTotalSupply = async (contractAddress) => {
  const contract = connectContract(contractAddress)

  const totalSupply = await contract.totalSupply()

  return ethers.formatEther(totalSupply + "")
}

const connectContract = (address, provider = providerReadOnly) => {
  return new Contract(address, PAIR_ABI, provider)
}
