import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { getParsedBalance } from "../../../utils"
import { Header } from "./header"
import { Spinner } from "../../spinner/Spinner"
import { DisabledButton } from "./disabledButton"
import { commit, getLimiterCommit, getTotalCommitments, started } from "../../../API/ico"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
import { saleUpdate } from "../../../state/state"
import { PRIVATE_ICO_ADDRESS } from "../../../constants"
import { ethers } from "ethers"

//eslint-disable-next-line
export const Sale = ({ icoAddress, info, isOnWhite }) => {
  const { active, library, account } = useWeb3React()
  const [balance, setBalance] = useState("0.00000")
  const [inputValue, setInputValue] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [icoInfo, setIcoInfo] = useState({ minCommit: 0, maxCommit: 0 })
  const [apr, setApr] = useState("TBA")
  const [aprPublic, setAprPublic] = useState("TBA")

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const updateBalance = async () => {
    setIsLoading(true)
    const value = await getParsedBalance(account)
    setBalance(value)
    setIsLoading(false)
  }

  const setMax = () => {
    if (active) updateBalance().then(() => setInputValue(balance))
  }

  const executeCommit = () => {
    commit(icoAddress, inputValue, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateBalance()
              saleUpdate.set(saleUpdate.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  const getData = async () => {
    const isStarted = await started(icoAddress)
    const limiter = await getLimiterCommit(icoAddress)

    setIcoInfo({ started: isStarted, minCommit: limiter.minCommit, maxCommit: limiter.maxCommit })
  }

  useEffect(() => {
    getTotalCommitments(icoAddress).then((value) => {
      const parsedValue = ethers.formatEther(value + "")

      if (parsedValue > 0) {
        setApr(((1 / parsedValue) * 1185 * 100).toFixed(2) + " %")
        setAprPublic(((1 / parsedValue) * 4310 * 100).toFixed(2) + " %")
      }
    })

    getData()
    if (!library || !active) {
      setBalance("0.00000")
      return
    }

    updateBalance()
  }, [active, library, account])

  return (
    <Header name="SALE">
      <p className="text-gray-500 pt-2">Farming Bonus</p>
      <p className="text-2xl">
        <span className="text-orange-color">APR</span> {icoAddress === PRIVATE_ICO_ADDRESS ? apr : aprPublic}
      </p>
      <div className="flex flex-row items-center justify-between py-2 my-2 w-10/12 h-16 border-orange-color rounded-md border-2">
        <input
          onChange={handleInputChange}
          value={inputValue}
          className="bg-transparent w-full ml-2"
          placeholder="Insert the amount"
        />
        <button
          onClick={setMax}
          className="px-4 h-10 mr-2 text-orange-color hover:bg-orange-color hover:text-white rounded-md border-2 border-orange-color"
        >
          MAX
        </button>
      </div>

      <div className="self-start ml-11 md:ml-14 py-1 text-gray-500 flex flex-row items-center">
        <p className="self-start pr-2">Balance:</p>
        {isLoading ? <Spinner size={"20"} /> : <p className="text-white"> {balance} ETH</p>}
      </div>
      {!active ? (
        <DisabledButton text={"Connect wallet"} />
      ) : !isOnWhite ? (
        <DisabledButton text={"Not whitelisted"} />
      ) : !icoInfo.started ? (
        <DisabledButton text={"Not Started"} />
      ) : inputValue == 0 ? (
        <DisabledButton text={"Insert amount first"} />
      ) : +inputValue > +balance ? (
        <DisabledButton text={"Input exceed Balance"} />
      ) : +inputValue > +icoInfo.maxCommit || +inputValue < +icoInfo.minCommit ? (
        <DisabledButton
          text={`${
            +inputValue > +icoInfo.maxCommit ? "Import exceed Max Commit" : "Import exceed Min Commit"
          }`}
        />
      ) : (
        <button onClick={executeCommit} className="my-2 w-10/12 h-16 bg-orange-color rounded-md">
          BUY and FARM
        </button>
      )}
    </Header>
  )
}
