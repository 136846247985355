import { useEffect, useState } from "react"
import { addressSlice, displayDecimals } from "../../utils"
import { MdContentCopy, MdOpenInNew } from "react-icons/md"
import { toast } from "react-toastify"
import { pairAllowance, pairApprove } from "../../API/pair"
import { useWeb3React } from "@web3-react/core"
import { ROUTER_ADDRESS, WETH_ADDRESS } from "../../constants"
import { ethers } from "ethers"
import { DisabledButton } from "../utils/disabledButton"
import { addLiquidityApproval, removeLiquidityUpdate } from "../../state/state"
import { ReceiptToast } from "../toast/receiptToast"
import { useStore } from "@nanostores/react"
import { removeLiquidity, removeLiquidityETH } from "../../API/router"

export const RemoveLiquidity = ({ pair, tokenSelection, setting }) => {
  const { active, account, library } = useWeb3React()
  const approveUpdate = useStore(addLiquidityApproval)
  //eslint-disable-next-line
  const [allowance, setAllowance] = useState("0")
  //eslint-disable-next-line
  const [inputValue, setInputValue] = useState(null)

  useEffect(() => {
    if (!library) return

    pairAllowance(account, ROUTER_ADDRESS, pair.pairAddress).then((value) => {
      setAllowance(ethers.formatEther(value + ""))
    })
  }, [active, library, approveUpdate, pair])

  const openAddressOnScanner = () => {
    window.open(`https://explorer.zksync.io/address/${pair.pairAddress}`, "_blank", "noreferrer")
  }

  const copyAddressToClipBoard = () => {
    navigator.clipboard.writeText(pair.pairAddress)
    toast("Pair address copied on clipboard")
  }

  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const executeApprove = () => {
    pairApprove(ROUTER_ADDRESS, inputValue, pair.pairAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              addLiquidityApproval.set(addLiquidityApproval.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  const executeRemove = () => {
    const maxToken0 = (pair.userReserve0 * inputValue) / pair.pairBalance
    const maxToken1 = (pair.userReserve1 * inputValue) / pair.pairBalance
    const minToken0 = (maxToken0 - (maxToken0 * setting.remove.slippage) / 100).toString()
    const minToken1 = (maxToken1 - (maxToken1 * setting.remove.slippage) / 100).toString()

    if (pair.token0 === "ETH" || pair.token0 === WETH_ADDRESS) {
      removeLiquidityETH(pair.token1, inputValue, minToken1, minToken0, account, library)
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            return
          }
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                removeLiquidityUpdate.set(removeLiquidityUpdate.get() + 1)
              } else toast("Transaction Failed!")
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
            })
        })
        .catch((e) => console.error(e))
    } else if (pair.token1 === "ETH" || pair.token1 === WETH_ADDRESS) {
      removeLiquidityETH(pair.token0, inputValue, minToken0, minToken1, account, library)
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            return
          }
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                removeLiquidityUpdate.set(removeLiquidityUpdate.get() + 1)
              } else toast("Transaction Failed!")
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
            })
        })
        .catch((e) => console.error(e))
    } else {
      removeLiquidity(pair.token0, pair.token1, inputValue, minToken0, minToken1, account, library)
        .then((tx) => {
          if (!tx) {
            toast("Transaction error!")
            return
          }
          toast(<ReceiptToast txHash={tx.hash} />)
          tx.wait()
            .then((res) => {
              if (res.status === 1) {
                toast("Transaction Success!")
                removeLiquidityUpdate.set(removeLiquidityUpdate.get() + 1)
              } else toast("Transaction Failed!")
            })
            .catch((e) => {
              console.log(e)
              toast("Transaction Failed!")
            })
        })
        .catch((e) => console.error(e))
    }
  }

  const setMax = () => {
    setInputValue(pair.pairBalance)
  }

  return (
    <div className="flex flex-col w-full pt-3">
      <button onClick={setMax} className="self-end flex flex-row items-center">
        <p>Available:{displayDecimals(pair.pairBalance)}</p>
      </button>
      <div className="w-full h-[50px] rounded-md border-2 border-card-border flex flex-row justify-between items-center mt-2 px-3">
        <p>{"LP"}</p>

        <input
          name="valueTokenA"
          onChange={handleInputChange}
          className="w-full bg-transparent text-end"
          type="number"
          placeholder="0"
          value={inputValue}
        />
      </div>
      <div className="w-full py-3 rounded-md border-2 border-card-border flex flex-col items-start mt-2 px-3 gap-2">
        <p className="text-gray-500">Pool</p>
        <div className="flex flex-row gap-2">
          <p>{addressSlice(pair.pairAddress)}</p>
          <button onClick={openAddressOnScanner} className="">
            <MdOpenInNew size={"23"} />
          </button>
          <button onClick={copyAddressToClipBoard} className="">
            <MdContentCopy size={"23"} />
          </button>
        </div>
        <p className="text-gray-500">Total Liquidity</p>
        <p>
          {Number(pair.reserve0).toFixed(2)}{" "}
          {pair.token0 === tokenSelection.tokenA ? tokenSelection.symbolA : tokenSelection.symbolB}
          {" / "}
          {Number(pair.reserve1).toFixed(2)}{" "}
          {pair.token0 !== tokenSelection.tokenA ? tokenSelection.symbolA : tokenSelection.symbolB}
        </p>
      </div>
      {!active ? (
        <DisabledButton text={"Connect wallet First!"} />
      ) : +inputValue > +pair.pairBalance ? (
        <DisabledButton text={"Amount exceed balance"} />
      ) : +inputValue > +allowance ? (
        <button onClick={executeApprove} className="mt-4 w-full h-[50px] bg-orange-color rounded-md">
          Approve
        </button>
      ) : (
        <button onClick={executeRemove} className="mt-4 w-full h-[50px] bg-orange-color rounded-md">
          Remove Liquidity
        </button>
      )}
    </div>
  )
}
