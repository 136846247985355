import { Claim } from "./claim"
import { GetXsword } from "./getXsword"
import { RedeemSword } from "./redeemSword"
//eslint-disable-next-line
export const Redeem = ({ userReedems, xSwordBalance, setXSwordBalance, swordBalance }) => {
  return (
    <>
      <div className="w-full flex flex-col gap-4">
        <GetXsword swordBalance={swordBalance} />
        <RedeemSword xSwordBalance={xSwordBalance} setXSwordBalance={setXSwordBalance} />
        <Claim userReedems={userReedems} />
      </div>
    </>
  )
}
