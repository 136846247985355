import { SWORD_ADDRESS, WETH_ADDRESS } from "../../constants"
import leaf from "../images/leaf.png"
import usdc from "../images/tokens/usdc.png"
import eth from "../images/tokens/eth.png"
import usdt from "../images/tokens/usdt.png"
import usdplus from "../images/tokens/USD+.png"
import mvx from "../images/tokens/mvx.png"
import zkbased from "../images/tokens/zkbased.png"
import dca from "../images/tokens/dca.png"
import slusdt from "../images/tokens/slusdt.png"
import iUSD from "../images/tokens/iUSD.png"
import ONEZ from "../images/tokens/ONEZ.png"
import ZKDX from "../images/collab/zkDX.leaf.png"
//import usdc from "../images/USDC.png"

/**
 * @type {Array<{address: string, name: string, img: string}>}
 * @description This array contains the base tokens that are used in the app
 * @property {string} address - The address of the token
 * @property {string} name - The name of the token
 * @property {string} img - The image of the token (URL)
 */
export const baseToken = [
  {
    address: SWORD_ADDRESS,
    name: "SWORD",
    img: leaf,
    display: true,
    decimals: 18,
  },
  {
    address: "ETH",
    name: "ETH",
    img: eth,
    display: true,
    decimals: 18,
  },
  {
    address: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    name: "USDC",
    img: usdc,
    display: true,
    decimals: 6,
  },
  {
    address: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
    name: "USDT",
    img: usdt,
    display: true,
    decimals: 6,
  },
  {
    address: "0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557",
    name: "USD+",
    img: usdplus,
    display: false,
    decimals: 6,
  },
  {
    address: WETH_ADDRESS,
    name: "WETH",
    img: eth,
    display: false,
    decimals: 18,
  },
  {
    address: "0xC8Ac6191CDc9c7bF846AD6b52aaAA7a0757eE305",
    name: "MVX",
    img: mvx,
    display: false,
    decimals: 18,
  },
  {
    address: "0x8a78ec16D4B0d3230441cbB8b43DD18c0b8Dc01c",
    name: "zkBASED",
    img: zkbased,
    display: false,
    decimals: 18,
  },
  {
    address: "0x8b69A4e5AEB67858E3d03d0124176E85C3DFa51d",
    name: "DCA",
    img: dca,
    display: false,
    decimals: 18,
  },
  {
    address: "0x496d88D1EFc3E145b7c12d53B78Ce5E7eda7a42c",
    name: "slUSDT",
    img: slusdt,
    display: false,
    decimals: 18,
  },
  {
    address: "0x1382628e018010035999A1FF330447a0751aa84f",
    name: "iUSD",
    img: iUSD,
    display: false,
    decimals: 18,
  },
  {
    address: "0x90059C32Eeeb1A2aa1351a58860d98855f3655aD",
    name: "ONEZ",
    img: ONEZ,
    display: true,
    decimals: 18,
  },
  {
    address: "0xC3a3Aa99B80845d22cC41DEa3bDB9b2a9eBe199a",
    name: "ZKDX",
    img: ZKDX,
    display: true,
    decimals: 18,
  },
]
