import { useEffect, useState } from "react"
import { farmingInfo } from "./farmingInfo"
import { FarmingItem } from "./farmingItem"
import { getTVL } from "../../API/defillama/defillama"
export const Farming = () => {
  const [openItem, setOpenItem] = useState(-1)
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024)
  //eslint-disable-next-line
  const [farmsActive, setFarmsActive] = useState(true)
  const [myOnly, setMyOnly] = useState(false)
  const [tvl, setTvl] = useState(0)
  const [search, setSearch] = useState("")

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024)
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  useEffect(() => {
    setMyOnly(false)
  }, [farmsActive])

  useEffect(() => {
    getTVL().then((value) => {
      setTvl(value)
    })
  }, [])

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
  }

  return (
    <div className="w-full h-full flex flex-col gap-2">
      <div className="w-full text-white font-semibold pt-4 max-md:pt-14 flex justify-center">
        <div className="w-10/12">
          <p className="text-2xl text-orange-color">TVL</p>
          <p className="text-4xl pb-3">${tvl}</p>
          <div className="flex items-center gap-4">
            <div className="relative w-full max-w-[400px]">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path stroke="currentColor" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
              </div>
              <input
                type="text"
                id="simple-search"
                className="bg-custom-color border border-card-border text-white text-sm rounded-md border-2 focus:ring-orange-color focus:border-orange-color block w-full pl-10 p-2.5"
                placeholder="Search by token symbol or pool address"
                required
                onChange={handleSearchChange}
                value={search}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-fit flex flex-col items-center justify-center pb-4">
        <div className="w-10/12 flex flex-row justify-between py-6">
          <div className="border flex flex-row border-card-border rounded-md border-2 text-white font-semibold overflow-hidden">
            <button
              onClick={() => setFarmsActive(true)}
              className={`sm:px-10 px-2 text-center h-[50px] ${farmsActive ? "bg-orange-color" : ""}`}
            >
              Active
            </button>
            <button
              onClick={() => setFarmsActive(false)}
              className={`sm:px-10 px-2 h-[50px] ${!farmsActive ? "bg-orange-color" : ""}`}
            >
              Ended
            </button>
          </div>
          <div className="w-fit flex sm:flex-row flex-col items-center gap-2">
            <p className="ml-3 text-sm text-white font-semibold">My Staked Only</p>
            <label className="relative inline-flex md:items-center items-end  cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={myOnly}
                onChange={() => setMyOnly(!myOnly)}
              />
              <div className="w-11 h-6 rounded-full peer bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-color"></div>
            </label>
          </div>
        </div>

        <div className="w-10/12 h-fit border border-card-border rounded-md border-2">
          {farmingInfo.map((info, index) => (
            <FarmingItem
              key={index}
              poolInfo={info}
              open={openItem}
              setOpen={setOpenItem}
              border={index === 0 ? "t" : index === farmingInfo.length - 1 ? "b" : "none"}
              isMobile={isMobile}
              myOnly={myOnly}
              farmsActive={farmsActive}
              search={search}
            />
          ))}
        </div>
        {farmingInfo.filter((farm) => farm.active === farmsActive).length == 0 ? (
          <p className="text-3xl pb-3 text-white font-semibold pt-10">
            0 Farms {farmsActive ? "Active" : "Ended"}
          </p>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
