import { useEffect, useState } from "react"
import { listAllPairs2 } from "../../API/factory"
import { addLiquidityState, providerReadOnly, removeLiquidityUpdate } from "../../state/state"
import { useWeb3React } from "@web3-react/core"
import { Spinner } from "../spinner/Spinner"
import { LiquidityCard } from "./LiquidityCard"
import { PairManaging } from "./pairManaging"
import { useStore } from "@nanostores/react"
import { parseWeiToValue } from "../../API/erc20"
import { getReserve, pairBalanceOf } from "../../API/pair"
import { WETH_ADDRESS } from "../../constants"
import { displayDecimals } from "../../utils"

export const Liquidity = () => {
  const { active, library, account } = useWeb3React()
  const liquidityUpdate = useStore(addLiquidityState)
  const pairUpdateListner = useStore(removeLiquidityUpdate)
  const [allLP, setAllLP] = useState([])
  const [loading, setLoading] = useState(false)
  const [pair, setPair] = useState()
  const [tokenSelection, setTokenSelection] = useState({
    tokenA: null,
    symbolA: null,
    tokenB: null,
    symbolB: null,
  })

  const getMyLiquidity = async () => {
    setLoading(true)
    listAllPairs2(account, providerReadOnly)
      .then(async (meLPs) => {
        setAllLP(meLPs.filter((lp) => +lp.pairBalance > 0))
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  useEffect(() => {
    if (!library) return
    getMyLiquidity()
  }, [library, account, liquidityUpdate, pairUpdateListner])

  const pairUpdate = async () => {
    if (!pair) return
    let pairBalance = "0.0"
    const reserves = await getReserve(pair.pairAddress)
    const reserve0 = await parseWeiToValue(reserves[0] + "", tokenSelection.tokenA)
    const reserve1 = await parseWeiToValue(reserves[1] + "", tokenSelection.tokenB)

    if (active) {
      pairBalance = await pairBalanceOf(account, pair.pairAddress)
    }

    setPair({ ...pair, reserve0, reserve1, pairBalance })
  }

  useEffect(() => {
    if (pairUpdateListner === 0) return
    pairUpdate()
  }, [pairUpdateListner])

  return (
    <>
      <div className={`flex flex-col items-center text-center w-full`}>
        <div className="text-white font-semibold pb-8 pt-4 max-md:pt-14">
          <p className="text-3xl pb-3">
            Liquidity <span className="text-orange-color">Pools</span>
          </p>
          <p>Pair your tokens to provide liquidity, Stake the LP tokens to earn SWORD and xSWORD</p>
        </div>

        <div className="flex flex-col md:flex-row w-full justify-center pb-4">
          <div className="w-full md:w-4/6 flex flex-col-reverse md:flex-row gap-4">
            <div className="w-full md:w-4/5">
              <div className=" max-w-5xl items-center mx-4">
                <div className=" rounded-md border-2 border-2 py-4 border-card-border text-sm bg-custom-color w-full text-white">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="text-ld font-bold text-center">Pool</div>
                    <div className="text-ld font-bold text-center">My Liquidity</div>
                    <div className="text-ld font-bold text-center">Action</div>
                  </div>
                </div>
                <div className="rounded-md border-2 border-2 py-4 border-card-border text-sm bg-custom-color w-full text-white font-semibold mt-2">
                  {loading ? (
                    <div className="flex justify-center items-center h-full y-4">
                      <Spinner size={"100"} />
                    </div>
                  ) : allLP.length > 0 ? (
                    allLP.map((lp, index) => (
                      <div key={index} className="grid grid-cols-3 gap-4 md:py-4 max-md:p-2 text-semibold">
                        <div className="flex justify-center items-center">
                          <p className="text-white my-auto text-center">
                            {(lp.token0Symbol === "WETH" ? "ETH" : lp.token0Symbol) +
                              "-" +
                              (lp.token1Symbol === "WETH" ? "ETH" : lp.token1Symbol)}
                          </p>
                        </div>
                        <div className="flex flex-col justify-center items-center">
                          <p className="text-white my-auto text-center text-semibold">
                            {displayDecimals(lp.userReserve0) +
                              " " +
                              (lp.token0Symbol === "WETH" ? "ETH" : lp.token0Symbol)}
                          </p>
                          <p className="text-white my-auto text-center text-semibold">
                            {displayDecimals(lp.userReserve1) +
                              " " +
                              (lp.token1Symbol === "WETH" ? "ETH" : lp.token1Symbol)}
                          </p>
                        </div>
                        <div className="flex justify-center items-center text-semibold">
                          <button
                            onClick={async () => {
                              setTokenSelection({
                                tokenA: lp.pairToken0 === WETH_ADDRESS ? "ETH" : lp.pairToken0,
                                symbolA: lp.token0Symbol === "WETH" ? "ETH" : lp.token0Symbol,
                                tokenB: lp.pairToken1 === WETH_ADDRESS ? "ETH" : lp.pairToken0,
                                symbolB: lp.token1Symbol === "WETH" ? "ETH" : lp.token0Symbol,
                              })
                              setPair({
                                pairAddress: lp.pairAddress,
                                pairBalance: lp.pairBalance,
                                token0: lp.pairToken0 === WETH_ADDRESS ? "ETH" : lp.pairToken0,
                                token1: lp.pairToken1 === WETH_ADDRESS ? "ETH" : lp.pairToken1,
                                reserve0: lp.reserve0,
                                reserve1: lp.reserve1,
                                symbol0: lp.token0Symbol === "WETH" ? "ETH" : lp.token0Symbol,
                                symbol1: lp.token1Symbol === "WETH" ? "ETH" : lp.token1Symbol,
                                totalSupply: lp.totalSupply,
                                ratio: lp.ratio,
                                userReserve0: lp.userReserve0,
                                userReserve1: lp.userReserve1,
                              })
                            }}
                            className="bg-orange-color h-10 w-3/4 max-w-[112px] rounded-md text-white"
                          >
                            Manage
                          </button>
                        </div>
                      </div>
                    ))
                  ) : active ? (
                    <div className="text-center">
                      <p className="text-white font-bold">No Liquidity found.</p>
                    </div>
                  ) : (
                    <div className="text-center">
                      <p className="text-white font-bold">Please, connect your wallet.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {pair ? (
              <PairManaging setPair={setPair} pair={pair} tokenSelection={tokenSelection} />
            ) : (
              <LiquidityCard
                pair={pair}
                setPair={setPair}
                tokenSelection={tokenSelection}
                setTokenSelection={setTokenSelection}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
