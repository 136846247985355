import { useState } from "react"
import { Modal } from "../../modal/modal"
import { InizializeReedem } from "./modal/inizializeRedeem"

export const RedeemSword = ({ xSwordBalance, setXSwordBalance }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <div className="bg-custom-color py-4 max-md:px-4 rounded-md border-2 border-card-border w-full text-center text-white font-semibold flex flex-col">
        <p className="text-2xl pb-2">
          Redeem <span className="text-orange-color">SWORD</span>
        </p>
        <p className="px-1">
          Redeem your <span className="text-orange-color">xSWORD</span> back into{" "}
          <span className="text-orange-color">SWORD</span> over a period of 15 - 90 days.
        </p>
        <div className="border-t border-card-border w-11/12 my-4 self-center" />
        <button
          onClick={() => setIsModalOpen(true)}
          className="w-11/12 h-[50px] mt-2 self-center text-orange-color border-orange-color font-semibold rounded-md border-2"
        >
          Redeem SWORD
        </button>
      </div>
      {isModalOpen ? (
        <Modal
          content={<InizializeReedem balance={xSwordBalance} setBalance={setXSwordBalance} />}
          setShowModal={setIsModalOpen}
        />
      ) : (
        ""
      )}
    </>
  )
}
