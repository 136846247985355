import leaf from "../../../images/leaf.png"
import { removeDecimals } from "../../../../utils"
import { DisabledButton } from "../../../utils/disabledButton"
import { useWeb3React } from "@web3-react/core"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../../toast/receiptToast"
import { updateInizializeReedem, xStakeUpdate } from "../../../../state/state"
import { useState } from "react"
import { deallocate } from "../../../../API/dividends"

export const Deallocate = ({ userDividendsInfo }) => {
  const { account, active, library } = useWeb3React()
  const [amount, setAmount] = useState(null)

  const handleInputChange = (e) => {
    const value = e.target.value
    setAmount(value)
  }

  const setMax = () => {
    setAmount(userDividendsInfo.allocation)
  }

  const execute = async () => {
    deallocate(account, amount, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
              xStakeUpdate.set(xStakeUpdate.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  return (
    <div className="md:w-[500px] h-fit bg-custom-color-solid rounded-md border-2 border-card-border flex flex-col items-center py-6 px-4 text-white font-semibold">
      <p className="text-xl">Deallocate xSWORD</p>
      <p className="text-gray-500 pt-2">
        {/* eslint-disable-next-line */}
        By deallocating xSWORD you won't earn a share of protocol earnings anymore.
      </p>
      <button onClick={setMax} className="self-end text-gray-500 pb-2">
        Available: {removeDecimals(userDividendsInfo.allocation)}
      </button>
      <div className="w-full h-[50px] border-gray-500 rounded-md border-2 px-4 flex items-center text-white">
        <p className="flex flex-row items-center">
          <img src={leaf} width={20} className="mr-2" /> xSWORD
        </p>
        <input
          onChange={handleInputChange}
          name="amount"
          type="number"
          className="w-10/12 bg-transparent text-end"
          placeholder="0"
          value={amount}
        />
      </div>
      {!active ? (
        <DisabledButton text={"Connect wallet First!"} />
      ) : +amount > +userDividendsInfo.allocation ? (
        <DisabledButton text={"Amount exceed Allocation!"} />
      ) : (
        <button onClick={execute} className="mt-6 px-4 w-full h-[46px] bg-orange-color rounded-md">
          Deallocate
        </button>
      )}
    </div>
  )
}
