import { useEffect, useState } from "react"
import Countdown from "react-countdown"
import { Link } from "react-router-dom"
import { CountdownRenderer } from "../../new-xsword/dividends/countDown"

//eslint-disable-next-line
export const PadDetailButton = ({ info, to }) => {
  const [label, setLabel] = useState("label test")

  const checkDate = () => {
    if (!info.endTimestamp || Date.now() > info.endTimestamp) {
      setLabel("ENDED")
    } else if (Date.now() < info.startTimestamp) {
      setLabel(<Countdown date={info.startTimestamp} renderer={CountdownRenderer} />)
    } else {
      setLabel("ENTER")
    }
  }

  useEffect(() => {
    checkDate()

    const intervalId = setInterval(() => {
      checkDate()
    }, 1000)

    return () => {
      clearInterval(intervalId)
    }
  })

  return (
    <>
      {label !== "ENDED" && label !== "ENTER" ? (
        <button disabled className=" w-10/12 h-12 self-center mb-4 bg-orange-color rounded-md">
          {label}
        </button>
      ) : (
        <Link to={to} className="flex justify-center w-full">
          <button className=" w-10/12 h-12 self-center mb-4 bg-orange-color rounded-md">{label}</button>
        </Link>
      )}
    </>
  )
}
