import { Accordion, AccordionBody, AccordionHeader } from "@material-tailwind/react"
import { useState } from "react"
import { Link } from "react-router-dom"
import { WalletConnection } from "./walletConnection"

export const MobileNavbar = ({ setShowModal }) => {
  const [isTradeMenuOpen, setIsTradeMenuOpen] = useState(false)
  const [isBridgeMenuOpen, setIsBridgeMenuOpen] = useState(false)

  return (
    <div className="flex flex-col gap-4 px-2 pb-4">
      <Accordion className={`bg-transparent`} open={isTradeMenuOpen}>
        <AccordionHeader
          className="py-0 hover:text-orange-color  border-none"
          onClick={() => setIsTradeMenuOpen(!isTradeMenuOpen)}
        >
          Trade
        </AccordionHeader>
        <AccordionBody className="py-0 pt-4 pl-4 flex flex-col gap-4">
          <Link
            className={`text-xl font-semibold ${
              location.pathname === "/" ? "text-orange-color" : ""
            } hover:text-orange-color`}
            to="/"
          >
            Swap
          </Link>
          <Link
            className={`text-xl font-semibold ${
              location.pathname === "/liquidity" ? "text-orange-color" : ""
            } hover:text-orange-color`}
            to="/liquidity"
          >
            Liquidity
          </Link>
        </AccordionBody>
      </Accordion>
      <Link
        className={`text-xl font-semibold ${
          location.pathname === "/farms" ? "text-orange-color" : ""
        } hover:text-orange-color`}
        to="/farms"
      >
        Farms
      </Link>
      <Link
        className={`text-xl font-semibold ${
          location.pathname === "/xsword" ? "text-orange-color" : ""
        } hover:text-orange-color`}
        to="/xsword"
      >
        xSword
      </Link>
      <Link
        className={`text-xl font-semibold ${
          location.pathname === "/launchpad" ? "text-orange-color" : ""
        } hover:text-orange-color`}
        to="/launchpad"
      >
        Launchpad
      </Link>
      <Accordion className={`bg-transparent`} open={isBridgeMenuOpen}>
        <AccordionHeader
          className="py-0 hover:text-orange-color  border-none"
          onClick={() => setIsBridgeMenuOpen(!isBridgeMenuOpen)}
        >
          Bridge
        </AccordionHeader>
        <AccordionBody className="py-0 pt-4 pl-4 flex flex-col gap-4">
          <Link
            to="https://app.rhino.fi/bridge?token=ETH"
            target="_blank"
            className={`text-xl font-semibold col-span-4 flex w-full flex-col hover:text-orange-color `}
          >
            Rhino
          </Link>
          <Link
            to="https://www.orbiter.finance/?source=Ethereum&dest=zkSync%20Era"
            target="_blank"
            className={`text-xl font-semibold col-span-4 flex w-full flex-col hover:text-orange-color`}
          >
            Orbiter
          </Link>
          <Link
            to="https://app.xy.finance/"
            target="_blank"
            className={`text-xl font-semibold col-span-4 flex w-full flex-col hover:text-orange-color`}
          >
            XY Finance
          </Link>
        </AccordionBody>
      </Accordion>
      <WalletConnection setShowModal={setShowModal} />
    </div>
  )
}
