export const publicLaunch = {
  name: "$SWORD",
  startDate: "07/06/2023 15:00 UTC",
  endDate: "10/06/2023 15:00 UTC",
  type: "Public",
  method: "Farming Overflow",
  target: "490 ETH",
  softCap: "None",
  hardCap: "490 ETH",
  farmingBonus: "40.000 $xSWORD",
  distribution: { "$ SWORD": 75, "$ xSWORD": 25 },
  swordAllocation: "40.000 $xSWORD",
  apr: "TBA",
  price: "0.00029518 ETH",
  init: "1.8 M",
  claimingProcess: "$SWORD and $xSWORD",
  symbol: "SWORD",
  symbol1: "xSWORD",
  farmingSymbol: "xSWORD",
}

export const privateLaunch = {
  name: "$SWORD",
  startDate: "05/06/2023 15:00 UTC",
  endDate: "06/06/2023 15:00 UTC",
  type: "Private",
  method: "Farming Overflow",
  target: "90 ETH",
  softCap: "20 ETH",
  hardCap: "90 ETH",
  farmingBonus: "10.000 $xSWORD",
  distribution: { "$ SWORD": 75, "$ xSWORD": 25 },
  swordAllocation: "10.000 $xSWORD",
  apr: "TBA",
  price: "0.00026471 ETH",
  init: "1.8 M",
  claimingProcess: "$SWORD and $xSWORD",
  symbol: "SWORD",
  symbol1: "xSWORD",
  farmingSymbol: "xSWORD",
}

export const ezkaliburUrlData = {
  github: "https://github.com/ezkalibur",
  twitter: "https://twitter.com/zkaliburDEX",
  discord: "https://t.co/RieYj5W17y",
  docs: "https://docs.ezkalibur.com/introduction/or-welcome-overview",
  trust: "https://github.com/solidproof/projects/tree/main/2023/ezKalibur",
}

export const launchHeaders = [
  { key: "startDate", name: "Start Date" },
  { key: "type", name: "Type of Sale" },
  { key: "endDate", name: "End Date" },
  { key: "target", name: "Target raise" },
]

export const launchZkdx = {
  name: "zkDX",
  startDate: "20/01/2024 08:00 UTC",
  startTimestamp: 1705737600000,
  endDate: "25/01/2024 08:00 UTC",
  endTimestamp: 1706169600000,
  type: "IDO",
  method: "Farming Overflow",
  target: "272 ETH",
  softCap: "None",
  hardCap: "270 ETH",
  farmingBonus: "200.000 $ZKDX",
  distribution: { TGE: 40, "Linear vesting till 28th July": 60 },
  swordAllocation: "200.000 $ZKDX",
  apr: "TBA",
  price: "0.0000034 ETH",
  init: "230 K",
  claimingProcess: "$ZKDX",
  symbol: "ZKDX",
  symbol1: undefined,
  farmingSymbol: "ZKDX",
  calculateClaim: true,
  vesting: true,
  vestingStart: 1706428800000,
}

export const zkdxUrlData = {
  github: "https://github.com/zkDX-DeFi",
  twitter: "https://twitter.com/zkDXio",
  discord: "https://discord.gg/ZukCWPaZbp",
  docs: "https://docs.zkdx.io/",
  trust: "https://github.com/zkDX-DeFi/zkdx-contracts/tree/main/audits",
  main: "https://zkdx.io",
}

export const zkdxDescription = `zkDX is a decentralized derivatives protocol on several L2 networks, which supports not just the trading of crypto perpetual, but also foreign currency, stocks, and various innovative financial derivatives.
zkDX integrates LayerZero_Labs technology to build an Omnichain Aggregator of derivative trading. 
This allows to concentrate the liquidity from different L2s and provides a seamless trading experience for users regardless of which network their assets are on.`
