import Countdown from "react-countdown"
import { removeDecimals } from "../../../utils"
import { CountdownRenderer } from "./countDown"

export const DividendsGeneralInfo = ({ dividendsInfo }) => {
  const calculateWeeksPassed = () => {
    const fixedDate = new Date("2023-08-20T15:00:00")
    const currentDate = new Date()
    const millisecondsInAWeek = 1000 * 60 * 60 * 24 * 7
    const weeksPassed = Math.floor((currentDate - fixedDate) / millisecondsInAWeek)

    return weeksPassed
  }

  return (
    <div className="grid grid-cols-2 grid-rows-2 h-[236px] border-y border-card-border mt-2 ">
      <div className="border-r border-b border-card-border text-center flex flex-col justify-center">
        <h5 className="text-orange-color font-bold pb-2 text-xl">
          {removeDecimals(dividendsInfo.totalAllocation)}
        </h5>
        <p className="text-white font-semibold">Total Allocated</p>
      </div>
      <div className="border-l border-b border-card-border text-center flex flex-col justify-center">
        <h5 className="text-orange-color font-bold pb-2 text-xl">{calculateWeeksPassed()}</h5>
        <p className="text-white font-semibold">Current Epoch</p>
      </div>
      <div className="border-r border-t border-card-border text-center flex flex-col justify-center">
        <h5 className="text-orange-color font-bold pb-2 text-xl">
          {dividendsInfo.apy == 0 ? 0 : removeDecimals(dividendsInfo.apy)}%
        </h5>
        <p className="text-white font-semibold">APY</p>
      </div>
      <div className="border-l border-t border-card-border text-center flex flex-col justify-center">
        <h5 className="text-orange-color font-bold pb-2 text-xl">
          {dividendsInfo.startTime ? (
            <Countdown date={dividendsInfo.startTime * 1000} renderer={CountdownRenderer} />
          ) : (
            "0d 0h 0m 0s"
          )}
        </h5>
        <p className="text-white font-semibold">Next Epoch</p>
      </div>
    </div>
  )
}
