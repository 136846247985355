import { Contract } from "@ethersproject/contracts"
import { providerReadOnly } from "../state/state"
import { SWORD_STAKE } from "../constants"
import SWORD_STAKE_ABI from "../ABI/SWORD_STAKE_ABI.json"

export const getUserRedeemLength = async (account) => {
  const contract = connectContract()
  return await contract.getUserRedeemLength(account)
}

export const getUserRedeem = async (account, index) => {
  const contract = connectContract()
  return await contract.getUserRedeem(account, index)
}

export const doInitializeReedem = async (amount, days, provider) => {
  const contract = connectContract(provider.getSigner())
  return await contract.initializeRedeem(amount, days)
}

export const finalizeReedem = async (index, provider) => {
  const contract = connectContract(provider.getSigner())
  return await contract.finalizeRedeem(index)
}

export const lock = async (value, provider) => {
  const contract = connectContract(provider.getSigner())
  return await contract.lock(value)
}

const connectContract = (provider = providerReadOnly, address = SWORD_STAKE) => {
  return new Contract(address, SWORD_STAKE_ABI, provider)
}
