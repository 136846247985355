import { useState } from "react"
import { Modal } from "../../modal/modal"
import { ConvertSword } from "./modal/convertSword"

export const GetXsword = ({ swordBalance }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <>
      <div className="bg-custom-color py-4 max-md:px-4 rounded-md border-2 border-card-border w-full text-center text-white font-semibold flex flex-col">
        <p className="text-2xl pb-2">
          Get <span className="text-orange-color">xSWORD</span>
        </p>
        <p>
          Convert <span className="text-orange-color">SWORD</span> to receive{" "}
          <span className="text-orange-color">xSWORD</span> and get exclusive benefits.
        </p>
        <div className="border-t border-card-border w-11/12 my-4 self-center" />
        <button
          onClick={() => setIsModalOpen(true)}
          className="w-11/12 h-[50px] mt-2 bg-orange-color self-center text-white font-semibold rounded-md"
        >
          Lock SWORD
        </button>
      </div>
      {isModalOpen ? (
        <Modal content={<ConvertSword swordBalance={swordBalance} />} setShowModal={setIsModalOpen} />
      ) : (
        ""
      )}
    </>
  )
}
