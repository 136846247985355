import { useEffect, useState } from "react"
import { Spinner } from "../spinner/Spinner"
import { symbol } from "../../API/erc20"
import { providerReadOnly } from "../../state/state"
import { TokenItem } from "./TokenItem"
import { baseToken } from "./baseToken"

export const TokenSearch = ({ address, selectToken, exit }) => {
  const [searching, setSearching] = useState(false)
  const [failed, setFailed] = useState(false)
  const [tokenInfo, setTokenInfo] = useState({
    address: null,
    symbol: null,
    img: null,
  })

  useEffect(() => {
    if (failed) setFailed(false)
    setSearching(true)
    symbol(address, providerReadOnly)
      .then((symbol) => {
        let img = ""

        baseToken.forEach((token) => {
          if (address === token.address) img = token.img
        })

        setTokenInfo({ address, symbol, img })
        setSearching(false)
      })
      .catch(() => {
        setSearching(false)
        setFailed(true)
      })
  }, [address])

  return (
    <>
      {searching ? (
        <div className="w-full flex justify-center py-5">
          <Spinner size={"50"} />
        </div>
      ) : failed ? (
        <p className="text-white font-bold">ADDRESS NOT VALID</p>
      ) : (
        <TokenItem
          key={tokenInfo.address}
          name={tokenInfo.symbol}
          img={tokenInfo.img}
          address={tokenInfo.address}
          selectToken={selectToken}
          exit={exit}
        />
      )}
    </>
  )
}
