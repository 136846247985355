import { Card } from "./card"

//eslint-disable-next-line
export const MainDetails = ({ icoAddress, info }) => {
  function ObjectToDiv({ data }) {
    // Estrai le chiavi dell'oggetto
    const keys = Object.keys(data)

    return (
      <div>
        {keys.map((key) => (
          <p key={key}>
            {data[key]} % {key}
          </p>
        ))}
      </div>
    )
  }

  return (
    <Card otherStyle="max-md:mb-4">
      <div className={`flex flex-col items-center text-center w-auto md:w-[480px] md:h-[410px] h-[450px] `}>
        <p className="text-3xl">
          MAIN <span className="text-orange-color">DETAILS</span>
        </p>
        <div className="flex justify-between h-full w-full pt-7">
          <div className="grid grid-cols-1 grid-rows-4 pl-5 min-w-1/2">
            <div className="text-left pr-2">
              <p className="text-gray-500">Start Date</p>
              <p className="text-lg">{info.startDate}</p>
            </div>
            <div className="text-left ">
              <p className="text-gray-500">Sale Type</p>
              <p className="text-lg">{info.type}</p>
            </div>
            <div className="text-left ">
              <p className="text-gray-500">Sale Method</p>
              <p className="text-lg">
                Farming <span className="text-orange-color">Overflow </span>{" "}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 grid-rows-4 pl-5 border-card-border max-w-1/2">
            <div className="text-left pr-2">
              <p className="text-gray-500">End Date</p>
              <p className="text-lg">{info.endDate}</p>
            </div>
            <div className="text-left ">
              <p className="text-gray-500">Farming bonus</p>
              <p className="text-lg text-orange-color">{info.farmingBonus}</p>
            </div>
            <div className="text-left ">
              <p className="text-gray-500">Distribution</p>
              {<ObjectToDiv data={info.distribution} />}
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}
