import btn from "../images/btn.png"
import down from "../images/down.png"
import arrowDown from "../images/arrow-down-2.png"
import { useStore } from "@nanostores/react"
import { pairSwap, providerReadOnly, swapConfig, updateinvertSwap } from "../../state/state"
import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { parseStringToNumberDecimal, buildPath, displayDecimals } from "../../utils"
import { balanceOf, parseValueToWeiString, parseWeiToValue } from "../../API/erc20"
import { getAmountsOut, getAmountsOutOriginal, getPair } from "../../API/router"
import { RouterSwapButton } from "./RouterSwapButton"
import { Spinner } from "../spinner/Spinner"
import { SelectToken } from "../selectToken/SelectToken"
import { baseToken } from "../selectToken/baseToken"
import { PairInfo } from "./PairInfo"
import { Tooltip } from "react-tooltip"
import "react-tooltip/dist/react-tooltip.css"
import { getReserve } from "../../API/pair"
import noSymbol from "../images/tokens/noSymbol.png"
import { ethers } from "ethers"
import { isMobile } from "react-device-detect"

export const SwapCard = ({ setOpenSetting, setTokensDashboard, invertSwap, swapAmounts, setSwapAmounts }) => {
  const { active, library, account } = useWeb3React()
  const swapConf = useStore(swapConfig)
  const updateInvert = useStore(updateinvertSwap)
  const [balanceTokenA, setBalanceTokenA] = useState("0")
  const [balanceTokenB, setBalanceTokenB] = useState("0")
  const [openSettingTokenA, setOpenSettingTokenA] = useState(false)
  const [openSettingTokenB, setOpenSettingTokenB] = useState(false)
  const [loadingBalance, setLoadingBalance] = useState(false)
  const [loadingQuote, setLoadingQuote] = useState(false)
  const [pairReserve, setPairReserve] = useState({ hasLiquidity: true, reserve: [] })
  const [previousSelection, setPreviousSelection] = useState({
    tokenA: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    tokenB: "0x240f765Af2273B0CAb6cAff2880D6d8F8B285fa4",
  })

  const [pairValid, setPairValid] = useState({ status: true, text: "" })

  const selectedTokenASetting = (address, symbol) => {
    swapConfig.set({ ...swapConfig.get(), tokenA: address, symbolA: symbol })
    updateQuote(true)
  }

  const selectedTokenBSetting = (address, symbol) => {
    swapConfig.set({ ...swapConfig.get(), tokenB: address, symbolB: symbol })
    updateQuote(true)
  }

  const updateAvaiableBalance = async () => {
    if (loadingBalance) return
    setLoadingBalance(true)
    const tokenA =
      swapConf.tokenA === "ETH"
        ? await providerReadOnly.getBalance(account)
        : await balanceOf(account, swapConf.tokenA, providerReadOnly)
    setBalanceTokenA(await parseWeiToValue(tokenA, swapConf.tokenA, providerReadOnly))

    const tokenB =
      swapConf.tokenB === "ETH"
        ? await providerReadOnly.getBalance(account)
        : await balanceOf(account, swapConf.tokenB, providerReadOnly)
    setBalanceTokenB(await parseWeiToValue(tokenB, swapConf.tokenB, providerReadOnly))

    setLoadingBalance(false)
  }

  const updateQuote = async () => {
    const path = buildPath()
    const config = swapConfig.get()

    if (!path) {
      setTokensDashboard([0, 0])
      setPairValid({ status: false, text: "Select different tokens address!" })
      return
    }

    let pair

    pair = await getPair(path[0], path[path.length - 1])
    if (!pair) {
      setTokensDashboard([0, 0])
      setPairValid({ status: false, text: "Pair not found!" })
      pairSwap.set(ethers.ZeroAddress)
      return
    }

    setPairValid({ status: true, text: "" })

    pairSwap.set(pair)

    let amountIn = await parseValueToWeiString("1", config.tokenA, providerReadOnly)
    const amountsOut = await getAmountsOutOriginal(amountIn, path, providerReadOnly)
    const parsed = await parseWeiToValue(amountsOut[amountsOut.length - 1], config.tokenB, providerReadOnly)
    const reserves = await getReserve(pair)
    setPairReserve({
      hasLiquidity: +(reserves[0] + "") > 0 && +(reserves[1] + "") > 0,
      reserve: reserves,
    })
    setTokensDashboard([1, parseStringToNumberDecimal(parsed, 4)])
  }

  const handleSwapValueChange = (e) => {
    swapConfig.set({ ...swapConfig.get(), swapValue: e.target.value })
    getQuote()
  }

  const setMax = () => {
    swapConfig.set({ ...swapConfig.get(), swapValue: balanceTokenA })
    getQuote()
  }

  const getQuote = async () => {
    const swapValueElementB = document.getElementById("swapValueB")
    if (swapValueElementB) {
      swapValueElementB.value = ""
    }
    setLoadingQuote(true)

    const path = buildPath()

    if (!path) {
      setSwapAmounts(["0", "0"])
      setLoadingQuote(false)
      setTokensDashboard(["0", "0"])
      setPairValid({ status: false, text: "Select different tokens address!" })
      return
    }

    const pair = await getPair(path[0], path[path.length - 1])

    if (!pair) {
      setSwapAmounts(["0", "0"])
      setLoadingQuote(false)
      setTokensDashboard(["0", "0"])
      setPairValid({ status: false, text: "Pair not found!" })
      return
    }

    updateQuote(true)

    await getAmountsOut(swapConfig.get().swapValue, path, providerReadOnly)
      .then(async (swapAmount) => {
        const parsed1 = await parseWeiToValue(swapAmount[0], path[0], providerReadOnly)
        const parsed2 = await parseWeiToValue(
          swapAmount[swapAmount.length - 1],
          path[path.length - 1],
          providerReadOnly
        )
        swapConfig.set({ ...swapConfig.get(), amountIn: parsed1, amountOut: parsed2 })
        setSwapAmounts([parsed1, parsed2])
        setLoadingQuote(false)
      })
      .catch(() => {
        setLoadingQuote(false)
      })
  }

  useEffect(() => {
    setPairValid({ status: true, text: "" })
    getQuote()
    if (!library) return
    if (!loadingBalance) updateAvaiableBalance()
  }, [library, account])

  useEffect(() => {
    const swapValueElement = document.getElementById("swapValue")
    if (swapValueElement) {
      swapValueElement.value = ""
    }

    updateQuote(true)

    if (active) {
      updateAvaiableBalance()
    }
  }, [updateInvert])

  useEffect(() => {
    if (!active) return

    if (swapConf.tokenA !== previousSelection.tokenA || swapConf.tokenB !== previousSelection.tokenB) {
      updateAvaiableBalance()
      setPreviousSelection({ tokenA: swapConf.tokenA, tokenB: swapConf.tokenB })
    }
  }, [swapConf])

  useEffect(() => {
    if (!active) {
      setBalanceTokenA(0)
      setBalanceTokenB(0)
      return
    }
  }, [active])

  return (
    <>
      {!isMobile ? (
        <div className="basis-4/6 max-md:hidden">
          <PairInfo changeDashboard={invertSwap} />
        </div>
      ) : (
        ""
      )}
      {openSettingTokenA || openSettingTokenB ? (
        <div id="setting-container " className="w-full max-md:px-4 md:basis-2/6">
          {openSettingTokenA ? (
            <SelectToken exit={setOpenSettingTokenA} selectToken={selectedTokenASetting} />
          ) : (
            <SelectToken exit={setOpenSettingTokenB} selectToken={selectedTokenBSetting} />
          )}
        </div>
      ) : (
        <div className="flex flex-col justify-between md:basis-2/6 w-full bg-custom-color md:p-5 py-5 px-4 mx-4 rounded-md border-2 border-card-border min-h-[550px] ">
          <div className="flex justify-between max-w-full">
            <p className="text-white text-3xl font-semibold">Swap</p>
            <img
              src={btn}
              className="w-9 cursor-pointer hover:filter-sepia"
              onClick={() => setOpenSetting(true)}
            />
          </div>
          <div className="pt-3">
            <div className="flex justify-between text-pgray-color font-400">
              <p>Pay</p>
              <button onClick={setMax} className="flex flex-row">
                <p className="mr-3">Available: </p>
                {loadingBalance ? <Spinner size={"20"} /> : <p>{displayDecimals(balanceTokenA)}</p>}
              </button>
            </div>
            <div className="flex items-center mt-1 border-card-border pt-1.5 pb-1.5 pl-2 pr-2 rounded-md border-2">
              <button onClick={() => setOpenSettingTokenA(true)} className="flex items-center w-fit mr-10">
                <img
                  className="w-5"
                  src={baseToken.find((item) => item.address === swapConf.tokenA)?.img || noSymbol}
                />
                <h5 className="font-bold text-sm text-white px-2">{swapConf.symbolA}</h5>
                <img src={down} className="h-2" />
              </button>
              <div className="font-bold text-white text-xl w-8/12 text-right">
                <input
                  type="number"
                  className="bg-transparent w-full text-right"
                  id="swapValue"
                  placeholder="0"
                  onChange={(e) => {
                    handleSwapValueChange(e)
                  }}
                  value={swapConf.swapValue}
                />
              </div>
            </div>
          </div>
          <button onClick={invertSwap}>
            <img src={arrowDown} className="h-9 mx-auto" />
          </button>
          <div>
            <div className="flex justify-between text-pgray-color font-400">
              <p>Receive (Estimated)</p>
              <div className="flex flex-row">
                <p className="mr-3">Available: </p>
                {loadingBalance ? <Spinner size={"20"} /> : <p>{displayDecimals(balanceTokenB)}</p>}
              </div>
            </div>
            <div className="flex justify-between items-center mt-1 border-card-border pt-1.5 pb-1.5 pl-2 pr-2 rounded-md border-2">
              <div className="flex items-center w-2/5">
                <button onClick={() => setOpenSettingTokenB(true)} className="flex items-center mr-4">
                  <img
                    className="w-5"
                    src={baseToken.find((item) => item.address === swapConf.tokenB)?.img || noSymbol}
                  />
                  <h5 className="font-bold text-sm text-white px-2">{swapConf.symbolB}</h5>
                  <img src={down} className="h-2" />
                </button>
              </div>
              <div className="font-bold w-8/12 text-white text-xl">
                <input
                  className="bg-transparent w-full text-right"
                  disabled
                  placeholder="0"
                  id="swapValueB"
                  value={Math.floor(swapAmounts[1] * 100000) / 100000}
                />
              </div>
            </div>
          </div>
          <div>
            {loadingQuote ? (
              <button
                disabled
                className="pt-2 pb-3 w-full bg-button-color rounded-md flex items-center justify-center"
              >
                <Spinner size={"30"} />
              </button>
            ) : !pairValid.status ? (
              <button
                disabled
                className="pt-2 pb-3 w-full bg-button-color rounded-md tex-lg font-bold text-white"
              >
                {pairValid.text}
              </button>
            ) : !pairReserve.hasLiquidity ? (
              <button
                disabled
                className="pt-2 pb-3 w-full bg-button-color rounded-md tex-lg font-bold text-white"
              >
                No liquidity
              </button>
            ) : !active ? (
              <button
                disabled
                className="pt-2 pb-3 w-full bg-button-color rounded-md tex-lg font-bold text-white"
              >
                Connect wallet first!
              </button>
            ) : (
              <RouterSwapButton updateBalance={updateAvaiableBalance} />
            )}
            {active ? (
              swapConf.swapValue ? (
                ""
              ) : (
                <p className="text-pgray-color font-400 text-center pt-2">
                  Enter an amount to see more trading details
                </p>
              )
            ) : (
              ""
            )}
          </div>
          <div className="border-b border-gray-500 pt-2"></div>
          <div className="flex justify-between">
            <div className="flex item-center justify-center">
              <p className="text-pgray-color font-400">Protocol Fees</p>
            </div>
            <Tooltip id="my-tooltip" />
            <div>
              <p className="text-orange-color">0.25%</p>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
