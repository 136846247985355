import { Contract } from "@ethersproject/contracts"
import XTOKEN_ABI from "../ABI/XTOKEN_ABI.json"
import { ethers } from "ethers"
import { providerReadOnly } from "../state/state"
import { XSWORD_ADDRESS } from "../constants"

export const name = async (contractAddress, provider) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.name()
}

export const symbol = async (contractAddress, provider) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.symbol()
}

export const decimal = async (contractAddress, provider) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.decimals()
}

export const totalSupply = async (contractAddress, provider) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.totalSupply()
}

export const transfer = async (to, value, contractAddress, provider) => {
  const contract = connectContract(provider.getSigner(), contractAddress)
  const parsedValue = await parseValueToWeiString(value, contractAddress, provider)
  return await contract.transfer(to, parsedValue)
}

export const transferFrom = async (from, to, value, contractAddress, provider) => {
  const contract = connectContract(provider.getSigner(), contractAddress)
  const parsedValue = await parseValueToWeiString(value, contractAddress, provider)
  return await contract.transferFrom(from, to, parsedValue)
}

export const approve = async (spender, value, contractAddress, provider) => {
  const contract = connectContract(provider.getSigner(), contractAddress)
  const parsedValue = await parseValueToWeiString(value, contractAddress, provider)
  return await contract.approve(spender, parsedValue)
}

export const approve2 = async (spender, value, provider, contractAddress = XSWORD_ADDRESS) => {
  const contract = connectContract(provider.getSigner(), contractAddress)
  return await contract.approve(spender, value)
}

export const allowance = async (
  owner,
  spender,
  contractAddress = XSWORD_ADDRESS,
  provider = providerReadOnly
) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.allowance(owner, spender)
}

export const parseValueToWeiString = async (value, contractAddress, provider) => {
  const tokenDecimal = await decimal(provider, contractAddress)
  const parsedValue = ethers.parseUnits(value, tokenDecimal)
  return parsedValue.toString()
}

export const getMinter = async (index, contractAddress, provider) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.getMinter(index)
}

export const getMinterLength = async (contractAddress, provider) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.getMinterLength()
}

export const mint = async (account, value, contractAddress, provider) => {
  const contract = connectContract(provider.getSigner(), contractAddress)
  return await contract.mint(account, value)
}

export const redeem = async (value, contractAddress, provider) => {
  const contract = connectContract(provider.getSigner(), contractAddress)
  return await contract.redeem(value)
}

export const balanceOf = async (account, contractAddress = XSWORD_ADDRESS, provider = providerReadOnly) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.balanceOf(account)
}

export const isMinter = async (account, contractAddress, provider) => {
  const contract = connectContract(provider, contractAddress)
  return await contract.isMinter(account)
}

const connectContract = (provider = providerReadOnly, address = XSWORD_ADDRESS) => {
  return new Contract(address, XTOKEN_ABI, provider)
}
