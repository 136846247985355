import { useState } from "react"
import leaf from "../../../images/leaf.png"
import { DisabledButton } from "../../../utils/disabledButton"
import { useWeb3React } from "@web3-react/core"
import { approve } from "../../../../API/erc20"
import { SWORD_ADDRESS, SWORD_STAKE } from "../../../../constants"
import { ethers } from "ethers"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../../toast/receiptToast"
import { updateInizializeReedem } from "../../../../state/state"
import { lock } from "../../../../API/swordStake"
//eslint-disable-next-line
export const ConvertSword = ({ swordBalance }) => {
  const { active, library } = useWeb3React()
  const [amount, setAmount] = useState(null)

  const handleInputChange = (e) => {
    const value = e.target.value
    setAmount(value)
  }

  const setMax = () => {
    setAmount(swordBalance.parsed)
  }

  const executeApprove = async () => {
    approve(SWORD_STAKE, amount, SWORD_ADDRESS, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  const execute = async () => {
    lock(ethers.parseEther(amount) + "", library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              updateInizializeReedem.set(updateInizializeReedem.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  return (
    <div className="md:w-[500px] h-fit bg-custom-color-solid rounded-md border-2 border-card-border flex flex-col items-center py-6 px-4 text-white font-semibold">
      <p className="text-xl">Convert SWORD</p>
      <p className="py-2 text-gray-500">Lock SWORD and get immediately xSWORD at 1:1 rate.</p>
      <p className="text-gray-500">
        xSWORD guarantees you access to new launchpad projects and can be staked into the Dividends contract
        to earn a portion of the protocol fees.
      </p>
      <button onClick={setMax} className="self-end text-gray-500 pb-2">
        Available: {Math.round(swordBalance.parsed * 100) / 100}
      </button>
      <div className="w-full h-[50px] border-card-border rounded-md border-2 px-4 flex items-center text-white">
        <p className="flex flex-row items-center">
          <img src={leaf} width={20} className="mr-2" /> SWORD
        </p>
        <input
          onChange={handleInputChange}
          name="amount"
          type="number"
          className="w-10/12 bg-transparent text-end"
          placeholder="0"
          value={amount}
        />
      </div>
      {!active ? (
        <DisabledButton text={"Connect wallet First!"} />
      ) : +amount > +swordBalance.parsed ? (
        <DisabledButton text={"Amount exceed Balance!"} />
      ) : +amount > +swordBalance.allowanceValue ? (
        <button onClick={executeApprove} className="mt-6 px-4 w-full h-[46px] bg-orange-color rounded-md">
          Approve
        </button>
      ) : (
        <button onClick={execute} className="mt-6 px-4 w-full h-[46px] bg-orange-color rounded-md">
          Stake
        </button>
      )}
    </div>
  )
}
