import { ezkaliburUrlData, launchHeaders } from "./launchInfo"
import { SocialIcon } from "../socialIcon"
import { Link } from "react-router-dom"
import { PadDetailButton } from "./padDetailsButton"

export const PadDetail = ({ info, name = "", to, socialUrls = ezkaliburUrlData, logo, leaf }) => {
  return (
    <div className="flex flex-col max-md:mb-4 bg-custom-color text-white font-semibold rounded-md border-2 border-card-border h-fit max-md:w-10/12">
      <div className="w-full h-16 border-b-2 border-card-border flex flex-row items-center justify-center text-2xl">
        <span className="text-orange-color pr-2">{info.name}</span> {name}
      </div>
      <div className="flex flex-row items-center justify-center py-2 px-3 w-full">
        {socialUrls.main ? (
          <Link to={socialUrls.main} target="_blank" className="flex items-center gap-2">
            <img src={leaf} className="h-6 pr-2" />
            <img src={logo} className="h-8" />
          </Link>
        ) : (
          <div className="flex items-center gap-2">
            <img src={leaf} className="h-6" />
            <img src={logo} className="h-8" />
          </div>
        )}
        <div className="pl-5">
          <SocialIcon size={20} mobileGap="2" urlData={socialUrls} />
        </div>
      </div>
      <div className="border-t w-10/12 self-center border-card-border"></div>
      <div to={to} className="grid grid-cols-2 grid-rows-2 h-48 py-4 pl-6">
        {launchHeaders.map((header) => (
          <>
            <div className={`flex justify-start ${header.key !== "type" ? "max-w-[115px]" : ""}`}>
              <div>
                <p className="self-start">{header.name}</p>
                <p className="text-orange-color">{info[header.key]}</p>
              </div>
            </div>
          </>
        ))}
      </div>
      <PadDetailButton info={info} to={to} />
    </div>
  )
}
