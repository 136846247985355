import { removeDecimals } from "../../utils"

//eslint-disable-next-line
export const XswordInfo = ({ xSwordBalance, redeemingXsword, xSwordAllocated }) => {
  return (
    <div className="grid md:grid-cols-4 grid-cols-2 gap-4 w-full text-center">
      <div className="bg-custom-color py-4 max-md:px-4 rounded-md border-2 border border-card-border">
        <h5 className="text-orange-color font-bold pb-2 text-2xl">
          {removeDecimals(+xSwordBalance.parsed + +redeemingXsword + +xSwordAllocated)}
        </h5>
        <p className="text-white font-semibold">Total xSWORD</p>
      </div>
      <div className="bg-custom-color py-4 max-md:px-4 rounded-md border-2 border border-card-border">
        <h5 className="text-orange-color font-bold pb-2 text-2xl">{removeDecimals(xSwordBalance.parsed)}</h5>
        <p className="text-white font-semibold">Available xSWORD</p>
      </div>
      <div className="bg-custom-color py-4 max-md:px-4 rounded-md border-2 border border-card-border">
        <h5 className="text-orange-color font-bold pb-2 text-2xl">{removeDecimals(xSwordAllocated)}</h5>
        <p className="text-white font-semibold">Allocated xSWORD</p>
      </div>
      <div className="bg-custom-color py-4 max-md:px-4 rounded-md border-2 border border-card-border">
        <h5 className="text-orange-color font-bold pb-2 text-2xl">{removeDecimals(redeemingXsword)}</h5>
        <p className="text-white font-semibold">Redeeming xSWORD</p>
      </div>
    </div>
  )
}
