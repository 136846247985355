import { useState } from "react"
import arrowLeft from "../images/arrowLeft.png"
import btn from "../images/btn.png"
import { AddLiquidity } from "./addLiquidity"
import { RemoveLiquidity } from "./removeLiquidity"
import { ethers } from "ethers"
import { SettingLiquidity } from "./settingLiquidity"

export const PairManaging = ({ pair, setPair, tokenSelection, origin = "add" }) => {
  const [type, setType] = useState(origin)
  const [setting, setSetting] = useState({
    add: { slippage: "0.5", deadLine: 90 },
    remove: { slippage: "0.5", deadLine: 90 },
  })
  const [isSettingOpen, setIseSettingOpen] = useState({ status: false, type: "add" })

  return (
    <div className="mx-4 md:w-[450px] ">
      <div className="px-4 rounded-md border-2 border-2 py-4 border-card-border bg-custom-color w-full text-white font-semibold">
        <div className="text-white flex justify-between">
          <button
            onClick={() => (isSettingOpen.status ? setIseSettingOpen({ status: false }) : setPair(null))}
            className="flex items-center self-start"
          >
            <img src={arrowLeft} className="w-8" />
          </button>
          {!isSettingOpen.status ? (
            <img
              src={btn}
              className="w-9 cursor-pointer hover:filter-sepia"
              onClick={() => setIseSettingOpen({ status: true, type })}
            />
          ) : (
            ""
          )}
        </div>
        {isSettingOpen.status ? (
          <SettingLiquidity
            setIseSettingOpen={setIseSettingOpen}
            isSettingOpen={isSettingOpen}
            setSetting={setSetting}
            setting={setting}
          />
        ) : (
          <>
            <div className="bg-gray-500 rounded-md my-3  h-[50px] flex flex-row justify-around font-semibold text-lg">
              <button
                onClick={() => setType("add")}
                className={`${type == "add" ? "bg-white text-orange-color" : "text-white"} w-1/2 rounded-md`}
              >
                Add
              </button>
              <button
                disabled={pair.pairAddress === ethers.ZeroAddress}
                onClick={() => setType("remove")}
                className={`${
                  type == "remove" ? "bg-white text-orange-color" : "text-white"
                } w-1/2 rounded-md`}
              >
                Remove
              </button>
            </div>
            {type === "add" ? (
              <AddLiquidity pair={pair} tokenSelection={tokenSelection} setting={setting} />
            ) : (
              <RemoveLiquidity pair={pair} tokenSelection={tokenSelection} setting={setting} />
            )}
          </>
        )}
      </div>
    </div>
  )
}
