import { launchZkdx, privateLaunch, publicLaunch, zkdxUrlData } from "./launchInfo"
import { PadDetail } from "./padDetails"

import ezkaliburLogo from "../../images/logo.png"
import ezkaliburLeaf from "../../images/leaf.png"
import zkdxLogo from "../../images/collab/zkdx-logo.png"

export const Launchpad = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-6 pt-10 w-full h-full">
        <div className="text-center text-white font-semibold">
          <h1 className="text-3xl pb-2">Launchpad</h1>
          <h3>
            Embrace the future of DeFi with e<span className="text-orange-color">ZK</span>alibur, the ultimate
            launchpad for cutting-edge blockchain project.
          </h3>
        </div>
        <div className="flex w-full justify-center md:flex-row flex-col md:gap-4 max-md:items-center max-md:pb-3">
          <PadDetail
            to={"/private-launch"}
            name={"PRIVATE"}
            info={privateLaunch}
            logo={ezkaliburLogo}
            leaf={ezkaliburLeaf}
          />
          <PadDetail
            to={"/public-launch"}
            name={"PUBLIC"}
            info={publicLaunch}
            logo={ezkaliburLogo}
            leaf={ezkaliburLeaf}
          />
          <PadDetail to={"/launch-zkdx"} info={launchZkdx} socialUrls={zkdxUrlData} logo={zkdxLogo} />
        </div>
      </div>
    </>
  )
}
