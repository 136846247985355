export const farmingInfo = [
  {
    index: 0,
    pair: "0xF6aF5785d59fc39A371C650cb6D3A6144C4dC85b",
    token0: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    symbol0: "USDC",
    decimals0: 6,
    token1: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    symbol1: "ETH",
    decimals1: 18,
    isStable: false,
    percentageRewards: 0.3741,
    active: true,
  },
  {
    index: 3,
    pair: "0xc8b6B3A4D2d8428EF3a940EAc1E32A7DdAdCB0f1",
    token0: "0x240f765Af2273B0CAb6cAff2880D6d8F8B285fa4",
    symbol0: "SWORD",
    decimals0: 18,
    token1: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    symbol1: "ETH",
    decimals1: 18,
    isStable: false,
    percentageRewards: 0.4146,
    active: true,
  },
  {
    index: 2,
    pair: "0xCF78D3C56Bc12138ea4D0b06a3F13810A7115B93",
    token0: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    symbol0: "USDC",
    decimals0: 6,
    token1: "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
    symbol1: "USDT",
    decimals1: 6,
    isStable: true,
    percentageRewards: 0.1213,
    active: true,
  },
  {
    index: 9,
    pair: "0xa776257Bef244eE1EC7abA80C7460B7E5b043e67",
    token0: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    symbol0: "ETH",
    decimals0: 18,
    token1: "0xC3a3Aa99B80845d22cC41DEa3bDB9b2a9eBe199a",
    symbol1: "ZKDX",
    decimals1: 18,
    isStable: false,
    percentageRewards: 0.0506,
    active: true,
  },
  {
    index: 1,
    pair: "0x0DfD96f6DbA1F3AC4ABb4D5CA36ce7Cb48767a13",
    token0: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    symbol0: "USDC",
    decimals0: 6,
    token1: "0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557",
    symbol1: "USD+",
    decimals1: 6,
    isStable: true,
    percentageRewards: 0.0051,
    active: false,
  },
  {
    index: 4,
    pair: "0x05D188B674f90c77684f29773641942e37074Aa5",
    token1: "0xC8Ac6191CDc9c7bF846AD6b52aaAA7a0757eE305",
    symbol1: "MVX",
    decimals0: 18,
    token0: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    symbol0: "ETH",
    decimals1: 18,
    isStable: false,
    percentageRewards: 0,
    active: false,
  },
  {
    index: 5,
    pair: "0xC6706c31a605f95562cA9ED66Ba61f15C35FA3b1",
    token0: "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    symbol0: "ETH",
    decimals0: 18,
    token1: "0x8b69A4e5AEB67858E3d03d0124176E85C3DFa51d",
    symbol1: "DCA",
    decimals1: 18,
    isStable: false,
    percentageRewards: 0.0091,
    active: false,
  },
  {
    index: 6,
    pair: "0x7C2c3735b2cB9e2a1B072f6a41cd01F33968F267",
    token0: "0x240f765Af2273B0CAb6cAff2880D6d8F8B285fa4",
    symbol0: "SWORD",
    decimals0: 18,
    token1: "0x8a78ec16D4B0d3230441cbB8b43DD18c0b8Dc01c",
    symbol1: "zkBASED",
    decimals1: 18,
    isStable: false,
    percentageRewards: 0,
    active: false,
  },
  {
    index: 7,
    pair: "0xadd1a3b5b3e6fbf9bae8AF1Ab8181744AA12E99F",
    token0: "0x496d88D1EFc3E145b7c12d53B78Ce5E7eda7a42c",
    symbol0: "slUSDT",
    decimals0: 18,
    token1: "0x1382628e018010035999A1FF330447a0751aa84f",
    symbol1: "iUSD",
    decimals1: 18,
    isStable: true,
    percentageRewards: 0,
    active: false,
  },
  {
    index: 8,
    pair: "0xD41BDC94898F3A38AD5e5b0eA239cFa2e6079060",
    token0: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    symbol0: "USDC",
    decimals0: 6,
    token1: "0x90059C32Eeeb1A2aa1351a58860d98855f3655aD",
    symbol1: "ONEZ",
    decimals1: 18,
    isStable: true,
    percentageRewards: 0.0254,
    active: true,
  },
]
