import { ConnectionButton } from "./connectionButton"
import { connections } from "./connector"

export const ConnectionMenu = () => {
  return (
    <div className="h-fit w-72 pt-4 px-4 rounded-md border-2 border border-card-border shadow bg-custom-color-solid">
      <p className="text-white text-start font-semibold">Connect Wallet</p>
      <div className="pt-6">
        {connections.map((connection, index) => (
          <ConnectionButton
            key={index}
            connector={connection.connector}
            img={connection.image}
            name={connection.name}
          />
        ))}
      </div>
    </div>
  )
}
