import { useWeb3React } from "@web3-react/core"
import leaf from "../../images/leaf.png"
import { DisabledButton } from "../../utils/disabledButton"
import { deposit } from "../../../API/farming"
import { stakeUpdate } from "../../../state/state"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
//eslint-disable-next-line
export const ClaimComponent = ({ index, userData }) => {
  //eslint-disable-next-line
  const { active, account, library } = useWeb3React()

  const execute = async () => {
    deposit(index, "0", account, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              stakeUpdate.set({ index, date: Date.now() })
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  return (
    <div className="flex flex-col items-center text-white font-semibold pt-4">
      <p className="text-xl">Claim Rewards</p>
      <div className="w-11/12 px-5 mt-3 h-[50px] rounded-md bg-transparent flex flex-row text-white justify-between items-center ">
        <div className="flex flex-row items-center">
          <img className="mr-2" src={leaf} width={20} />
          <p>SWORD</p>
        </div>
        <p>{(userData.reward * 0.2).toFixed(5)}</p>
      </div>
      <div className="w-11/12 px-5 mt-3 h-[50px] rounded-md bg-transparent flex flex-row text-white justify-between items-center ">
        <div className="flex flex-row items-center">
          <img className="mr-2" src={leaf} width={20} />
          <p>xSWORD</p>
        </div>
        <p>{(userData.reward * 0.8).toFixed(5)}</p>
      </div>

      <div className="w-11/12">
        {!active ? (
          <DisabledButton text={"Connect wallet First!"} />
        ) : userData.reward == 0 ? (
          <DisabledButton text={"Nothing to Claim!"} />
        ) : (
          <button onClick={execute} className="my-2 w-full  px-4 h-[46px] bg-orange-color rounded-md">
            Claim
          </button>
        )}
      </div>
    </div>
  )
}
