import React, { useState } from "react"
import { SwapSetting } from "./SwapSetting"
import { SwapCard } from "./SwapCard"
import { swapConfig, updateinvertSwap } from "../../state/state"
import { useStore } from "@nanostores/react"

export const Swap = () => {
  const swapConf = useStore(swapConfig)
  const [openSetting, setOpenSetting] = useState(false)
  const [tokensDashboard, setTokensDashboard] = useState(["0", "0"])
  const [swapAmounts, setSwapAmounts] = useState(["0", "0"])

  const invertSwap = () => {
    const previusTokenA = swapConf.tokenA
    const previusSymbolA = swapConf.symbolA
    swapConfig.set({
      ...swapConfig.get(),
      tokenA: swapConf.tokenB,
      tokenB: previusTokenA,
      symbolA: swapConf.symbolB,
      symbolB: previusSymbolA,
      swapValue: 0,
      amountOut: 0,
      amountIn: 0,
    })

    setSwapAmounts([0, 0])
    updateinvertSwap.set(updateinvertSwap.get() + 1)
  }

  return (
    <>
      <div className="">
        <div className="flex flex-row justify-center gap-6 max-w-7xl mx-auto pt-16 md:pt-36 pb-10">
          {openSetting ? (
            <div id="setting-container">
              <SwapSetting
                setOpenSetting={setOpenSetting}
                tokensDashboard={tokensDashboard}
                invertSwap={invertSwap}
              />
            </div>
          ) : (
            <SwapCard
              setOpenSetting={setOpenSetting}
              setTokensDashboard={setTokensDashboard}
              invertSwap={invertSwap}
              setSwapAmounts={setSwapAmounts}
              swapAmounts={swapAmounts}
            />
          )}
        </div>
      </div>
    </>
  )
}
