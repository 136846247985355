import { Claimable } from "./claimable"

export const Claim = ({ userReedems }) => {
  return (
    <div className="bg-custom-color py-4 rounded-md border-2 border border-card-border w-full text-center">
      <h5 className="text-white font-bold">My Redeems</h5>
      <div className="grid grid-cols-3 gap-4 mt-4 py-2 font-semibold text-white border-t border-b bg-custom-color border-card-border">
        <div className="">Days Left</div>
        <div className="">Amount</div>
        <div className="">Claim</div>
      </div>
      <div className="flex flex-col h-full max-h-[200px] overflow-auto">
        {userReedems.length == 0 ? (
          <p className="text-white font-semibold pt-4">No pending redeems</p>
        ) : (
          userReedems.map((reedem, index) => <Claimable key={index} index={index} reedem={reedem} />)
        )}
      </div>
    </div>
  )
}
