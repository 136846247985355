import { useWeb3React } from "@web3-react/core"
import { WETH_ADDRESS, XSWORD_ADDRESS } from "../../../constants"
import { useEffect, useState } from "react"
import { harvestDividends, pendingDividendsAmount } from "../../../API/dividends"
import { ethers } from "ethers"
import { toast } from "react-toastify"
import { ReceiptToast } from "../../toast/receiptToast"
import { removeDecimals } from "../../../utils"
import leaf from "../../images/leaf.png"
import { convertSwordToUsd, getETHPrice } from "../../farming/farmingUtility"
import { baseToken } from "../../selectToken/baseToken"

//eslint-disable-next-line
const rewardAddress = [
  { address: XSWORD_ADDRESS, name: "xSWORD", decimal: 18 },
  { address: WETH_ADDRESS, name: "WETH", decimal: 18 },
  { address: "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4", name: "USDC", decimal: 6 },
]

export const UserPendingRewards = () => {
  //eslint-disable-next-line
  const { account, library, active } = useWeb3React()
  //eslint-disable-next-line
  const [rewardsArray, setRewardsArray] = useState([])
  const [swordValue, setSwordValue] = useState(0)
  const [ethPrice, setETHPrice] = useState(0)

  const getRewards = async () => {
    const pendingRewards = []
    rewardAddress.forEach(async (reward) => {
      const pending = await pendingDividendsAmount(reward.address, account)
      pendingRewards.push({
        address: reward.address,
        amount: ethers.formatUnits(pending + "", reward.decimal),
      })
    })
    await setTokenPrice()
    setRewardsArray(pendingRewards)
  }

  const execute = (tokenAddress) => {
    harvestDividends(tokenAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              getRewards()
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  useEffect(() => {
    if (!active) {
      setRewardsArray([])
      return
    }

    getRewards()
  }, [account, active, ethPrice])

  const setTokenPrice = async () => {
    const sword = await convertSwordToUsd(1)

    setSwordValue(sword)

    const eth = await getETHPrice()
    setETHPrice(eth)
  }
  return (
    <>
      <p className="text-2xl pt-3">Your Rewards </p>
      <div className="border-t border-card-border w-11/12 my-4 self-center" />
      <div className="flex flex-col w-full gap-4 justify-center items-center">
        {rewardAddress.map((reward, index) => {
          const pending =
            rewardsArray.length === 0
              ? { amount: 0 }
              : rewardsArray.find((pending) => pending.address === reward.address)

          const amount = !pending || +pending.amount === 0 ? 0 : +pending.amount
          let usdValue =
            reward.address === WETH_ADDRESS ? ethPrice : reward.address === XSWORD_ADDRESS ? swordValue : 1
          let convertedAmount = amount * usdValue

          convertedAmount = convertedAmount === 0 ? "0.0" : removeDecimals(convertedAmount)
          return (
            <div key={index} className="grid grid-cols-2 w-10/12 gap-10 items-center text-center">
              <div className="w-full flex justify-start">
                <div className="flex flex-row items-center gap-3">
                  <div className="border rounded-full p-1 min-w-[42px] border-card-border bg-custom-color">
                    <img
                      className="w-8"
                      src={baseToken.find((item) => item.address === reward.address)?.img || leaf}
                    />
                  </div>
                  <div className="flex flex-col self-start items-start">
                    <p className="text-sm">{reward.name}</p>
                    <p>
                      {removeDecimals(amount, reward.address === WETH_ADDRESS ? 6 : 2)}
                      {reward.address !== "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4"
                        ? `(${removeDecimals(convertedAmount)}$)`
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full flex justify-end">
                <button
                  className="border-orange-color text-orange-color rounded-md border-2 test-orange-color h-full w-fit px-4 py-2 self-end"
                  onClick={() => execute(reward.address)}
                >
                  Claim
                </button>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
