import { Menu, MenuHandler, MenuItem, MenuList, Navbar } from "@material-tailwind/react"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import logo from "../images/logo.png"
import { IoIosArrowDown } from "react-icons/io"
import { MobileNavbar } from "./mobileNavbar"
import { WalletConnection } from "./walletConnection"
import { useWeb3React } from "@web3-react/core"
import { CHAIN } from "../../constants"
import { Modal } from "../modal/modal"
import { ConnectionInfo } from "../connection/connectionInfo"
import { ConnectionMenu } from "../connection/connectionMenu"

export const Nav = () => {
  const { active, chainId } = useWeb3React()
  const [openNav, setOpenNav] = useState(false)
  const [isTradeMenuOpen, setIsTradeMenuOpen] = useState(false)
  const [isBridgeMenuOpen, setIsBridgeMenuOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const tradeTriggers = {
    onMouseEnter: () => setIsTradeMenuOpen(true),
    onMouseLeave: () => setIsTradeMenuOpen(false),
  }

  const bridgeTriggers = {
    onMouseEnter: () => setIsBridgeMenuOpen(true),
    onMouseLeave: () => setIsBridgeMenuOpen(false),
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      let windowWidth = window.innerWidth
      if (windowWidth >= 768) {
        setOpenNav(false)
      }
    })
  }, [])

  useEffect(() => {
    if (active && chainId !== CHAIN) {
      setShowModal(true)
    }
  }, [active, chainId, showModal])

  return (
    <Navbar className="p-0 rounded-none bg-[#1B1F2250] border-none">
      <div className=" lg:w-10/12 max-lg:mx-2 mx-auto h-16 flex flex-row items-center justify-between text-white">
        <Link to={"https://www.ezkalibur.com"} className="min-w-fit pt-1">
          <button>
            <img src={logo} className="h-8" />
          </button>
        </Link>
        <div className="w-full hidden md:flex flex-row items-start gap-4 pl-4 font-semibold">
          <Menu open={isTradeMenuOpen} handler={setIsTradeMenuOpen}>
            <MenuHandler>
              <button>
                <MenuItem
                  className={`p-0 items-center gap-2 text-blue-gray-900 flex border-none hover:text-orange-color ${
                    location.pathname === "/" || location.pathname === "/liquidity" ? "text-orange-color" : ""
                  }`}
                >
                  Trade{" "}
                  <IoIosArrowDown
                    strokeWidth={2}
                    className={`h-4 w-4 transition-transform ${isTradeMenuOpen ? "rotate-180" : ""}`}
                  />
                </MenuItem>
              </button>
            </MenuHandler>
            <MenuList
              {...tradeTriggers}
              className="w-[93px] mt-[15px] rounded-none border-none bg-[#1B1F22] hidden md:flex flex-col text-white gap-3 p-3 font-semibold"
            >
              <Link
                to={"/"}
                className={`col-span-4 flex w-full flex-col hover:text-orange-color ${
                  location.pathname === "/" ? "text-orange-color" : ""
                }`}
              >
                Swap
              </Link>
              <Link
                to={"/liquidity"}
                className={`col-span-4 flex w-full flex-col hover:text-orange-color ${
                  location.pathname === "/liquidity" ? "text-orange-color" : ""
                }`}
              >
                Liquidity
              </Link>
            </MenuList>
          </Menu>
          <Link
            className={`${location.pathname === "/farms" ? "text-orange-color" : ""} hover:text-orange-color`}
            to="/farms"
          >
            Farms
          </Link>
          <Link
            className={`${
              location.pathname === "/xsword" ? "text-orange-color" : ""
            } hover:text-orange-color`}
            to="/xsword"
          >
            xSword
          </Link>
          <Link
            className={`${
              location.pathname === "/launchpad" ? "text-orange-color" : ""
            } hover:text-orange-color`}
            to="/launchpad"
          >
            Launchpad
          </Link>
          <Menu open={isBridgeMenuOpen} handler={setIsBridgeMenuOpen}>
            <MenuHandler>
              <button>
                <MenuItem
                  className={`p-0 items-center gap-2 text-blue-gray-900 flex border-none hover:text-orange-color`}
                >
                  Bridge{" "}
                  <IoIosArrowDown
                    strokeWidth={2}
                    className={`h-4 w-4 transition-transform ${isBridgeMenuOpen ? "rotate-180" : ""}`}
                  />
                </MenuItem>
              </button>
            </MenuHandler>
            <MenuList
              {...bridgeTriggers}
              className="w-[93px] mt-[15px] rounded-none border-none bg-[#1B1F22] flex flex-col text-white gap-3 p-3 font-semibold"
            >
              <Link
                to="https://app.rhino.fi/bridge?token=ETH"
                target="_blank"
                className={`col-span-4 flex w-full flex-col hover:text-orange-color `}
              >
                Rhino
              </Link>
              <Link
                to="https://www.orbiter.finance/?source=Ethereum&dest=zkSync%20Era"
                target="_blank"
                className={`col-span-4 flex w-full flex-col hover:text-orange-color`}
              >
                Orbiter
              </Link>
              <Link
                to="https://app.xy.finance/"
                target="_blank"
                className={`col-span-4 flex w-full flex-col hover:text-orange-color`}
              >
                XY Finance
              </Link>
            </MenuList>
          </Menu>
        </div>
        <div className="max-md:hidden">
          <WalletConnection setShowModal={setShowModal} />
        </div>
        <button className="h-6 w-6 bg-transparent md:hidden" onClick={() => setOpenNav(!openNav)}>
          {openNav ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              className="h-6 w-6"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          )}
        </button>
      </div>
      {openNav ? (
        <div className="h-fit" style={{ height: "fit-content" }}>
          <MobileNavbar setShowModal={setShowModal} />
        </div>
      ) : (
        ""
      )}
      {showModal ? (
        <Modal setShowModal={setShowModal} content={active ? <ConnectionInfo /> : <ConnectionMenu />} />
      ) : null}
    </Navbar>
  )
}
