import { useStore } from "@nanostores/react"
import { useWeb3React } from "@web3-react/core"
import { stakeUpdate } from "../../state/state"
import { useEffect, useState } from "react"
import { pendingMeerkat, userInfo } from "../../API/farming"
import { ethers } from "ethers"
import { pairAllowance, pairBalanceOf } from "../../API/pair"
import { FARMING_ADDRESS } from "../../constants"
import { calculateTVL, calculateTVLStable, convertSwordToUsd, getAPR, getAPY } from "./farmingUtility"
import { Accordion, AccordionHeader, AccordionBody } from "@material-tailwind/react"
import "./farmingItemStyle.css"
import { FarmingItemHeader } from "./farmiItemHeader"
import { FarminItemBody } from "./farmingItemBody"

export const FarmingItem = ({ poolInfo, open, setOpen, border, isMobile, myOnly, farmsActive, search }) => {
  const stakeUpdateListner = useStore(stakeUpdate)
  const { account, library } = useWeb3React()
  const [loading, setIsLoading] = useState(true)
  const [loadingUserInfo, setLoadingUserInfo] = useState(false)
  const [poolData, setPoolData] = useState({})
  const [userData, setUserData] = useState({ lpBalance: 0, amountStaked: 0, reward: 0 })
  const [tvl, setTvl] = useState(0)
  const [apr, setApr] = useState(0)
  const [rewards, setRewards] = useState(0)
  const [show, setShow] = useState(true)

  const getUserInfo = async (pair = poolInfo.pair) => {
    setLoadingUserInfo(true)
    //user info dal contratto di staking
    const user = await userInfo(poolInfo.index, account)
    const amountStaked = ethers.formatEther(user[0] + "")
    //balance di lp
    const lpBalance = await pairBalanceOf(account, pair)
    //reward
    let reward = await pendingMeerkat(poolInfo.index, account).catch(() => {
      return "0"
    })
    reward = ethers.formatEther(reward + "")
    //get lp allowance
    let allowance = await pairAllowance(account, FARMING_ADDRESS, pair)
    allowance = ethers.formatEther(allowance + "")

    convertSwordToUsd(reward).then((value) => {
      setRewards(value)
    })

    setUserData({ lpBalance, amountStaked, reward, allowance })
    setLoadingUserInfo(false)
  }

  const getPoolInfo = async () => {
    setIsLoading(true)
    const tokensInfo = { symbol0: poolInfo.symbol0, symbol1: poolInfo.symbol1 }
    const lpBalance = await pairBalanceOf(FARMING_ADDRESS, poolInfo.pair)
    if (poolInfo.isStable) {
      const currentTvl = await calculateTVLStable(poolInfo, lpBalance)
      const currentApr = await getAPR(currentTvl, poolInfo.percentageRewards, poolInfo)
      setApr(getAPY(currentApr))
      setTvl(currentTvl)
    } else {
      const currentTvl = await calculateTVL(lpBalance, poolInfo)
      setTvl(currentTvl)
      const currentApr = await getAPR(currentTvl, poolInfo.percentageRewards, poolInfo)
      setApr(getAPY(currentApr))
    }
    setPoolData({ ...tokensInfo, pair: poolInfo.pair, lpBalance })
    setIsLoading(false)
  }

  useEffect(() => {
    getPoolInfo()
  }, [])

  useEffect(() => {
    if (!library) return
    getUserInfo()
  }, [account, library, poolData])

  useEffect(() => {
    if (!stakeUpdateListner) return
    if (stakeUpdateListner.index !== poolInfo.index) return
    getPoolInfo()
  }, [stakeUpdateListner])

  const openAccordion = () => {
    setOpen(open === poolInfo.index ? -1 : poolInfo.index)
  }

  useEffect(() => {
    if (!loading) {
      setShow(myOnly && +userData.amountStaked === 0)
    } else {
      setShow(false)
    }
  }, [myOnly, userData])

  useEffect(() => {
    console.log("FARMING-ITEMS::", search ? search.toLowerCase() : search, poolData)
  }, [search])

  return (
    <>
      <div
        className={`${show ? "hidden" : ""} ${
          farmsActive ? (poolInfo.active ? "" : "hidden") : !poolInfo.active ? "" : "hidden"
        }
        
        ${
          search && search !== ""
            ? poolInfo.symbol0.toLowerCase().includes(search.toLowerCase()) ||
              poolInfo.symbol1.toLowerCase().includes(search.toLowerCase()) ||
              search.toLowerCase() === poolInfo.pair.toLowerCase()
              ? ""
              : "hidden"
            : ""
        }
        `}
      >
        <Accordion
          className={`custom-acc-header bg-custom-color ${
            border === "t" ? "rounded-t-md" : border === "b" ? "rounded-b-md" : ""
          }  ${border === "b" ? "border-none" : "border-b"} border-card-border`}
          open={open === poolInfo.index}
        >
          <AccordionHeader
            className={`acc-header ${
              open === poolInfo.index ? "border-b border-card-border" : "border-none"
            } py-6`}
            about=""
            onClick={() => {
              if (isMobile) openAccordion()
            }}
          >
            <FarmingItemHeader
              poolInfo={poolInfo}
              setOpen={openAccordion}
              open={open === poolInfo.index}
              loading={loading}
              loadingUserInfo={loadingUserInfo}
              userData={userData}
              poolData={poolData}
              tvl={tvl}
              apr={apr}
              rewards={rewards}
            />
          </AccordionHeader>
          <AccordionBody className="py-0 bg-custom-color-semisolid">
            <FarminItemBody
              poolInfo={poolInfo}
              userData={userData}
              poolData={poolData}
              rewards={rewards}
              tvl={tvl}
              loadingUserInfo={loadingUserInfo}
            />
          </AccordionBody>
        </Accordion>
      </div>
    </>
  )
}
