import { BsTwitter, BsDiscord, BsBookHalf, BsGithub } from "react-icons/bs"
import { GiSlashedShield } from "react-icons/gi"
import { Link } from "react-router-dom"
import { ezkaliburUrlData } from "./pad/launchInfo"

export const SocialIcon = ({ size, gap = "5", urlData = ezkaliburUrlData }) => {
  return (
    <div className={`flex flex-row gap-${gap} max-md:gap-2`}>
      {urlData.github ? (
        <Link to={urlData.github} target="_blank">
          <BsGithub className="App-logo" size={size} color="#D97E1D" />
        </Link>
      ) : (
        ""
      )}
      {urlData.twitter ? (
        <Link to={urlData.twitter} target="_blank">
          <BsTwitter size={size} color="#D97E1D" />
        </Link>
      ) : (
        ""
      )}
      {urlData.discord ? (
        <Link to={urlData.discord} target="_blank">
          <BsDiscord size={size} color="#D97E1D" />
        </Link>
      ) : (
        ""
      )}
      {urlData.docs ? (
        <Link to={urlData.docs} target="_blank">
          <BsBookHalf size={size} color="#D97E1D" />
        </Link>
      ) : (
        ""
      )}
      {urlData.trust ? (
        <Link to={urlData.trust} className={`w-[${size}px]`} target="_blank">
          <GiSlashedShield size={size} color="#D97E1D" />
        </Link>
      ) : (
        ""
      )}
    </div>
  )
}
