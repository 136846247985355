import { Contract } from "@ethersproject/contracts"
import FACTORY_ABI from "../ABI/FACTORY_ABI.json"
import { FACTORY_ADDRESS } from "../constants"
import { pairBalanceOf, getReserve, getPairToken, getPairName, getSymbol, getTotalSupply } from "./pair"
import { providerReadOnly } from "../state/state"
import { parseWeiToValue } from "./erc20"

export const listAllPairs = async (account, provider) => {
  const contract = connectContract(provider)

  const totalPairs = parseFloat(await contract.allPairsLength())
  const pairs = []

  for (let i = 0; i < totalPairs; i++) {
    const pairAddress = await contract.allPairs(i)
    var pairBalance = await pairBalanceOf(account, pairAddress, provider)

    let pairMeInformation = {}
    pairMeInformation.pairBalance = pairBalance
    pairMeInformation.pairAddress = pairAddress
    pairMeInformation.pairName = await getPairName(pairAddress, provider)
    pairMeInformation.pairToken0 = await getPairToken(0, pairAddress, provider)
    pairMeInformation.pairToken1 = await getPairToken(1, pairAddress, provider)
    pairMeInformation.token0Symbol = await getSymbol(pairMeInformation.pairToken0, provider)
    pairMeInformation.token1Symbol = await getSymbol(pairMeInformation.pairToken1, provider)
    pairMeInformation.totalSupply = await getTotalSupply(pairAddress)

    pairs.push(pairMeInformation)
  }

  return pairs
}

export const listAllPairs2 = async (account, provider = providerReadOnly) => {
  const contract = connectContract(provider)

  const totalPairs = parseFloat(await contract.allPairsLength())
  const pairs = []

  const promises = []

  for (let i = 0; i < totalPairs; i++) {
    const pairAddressPromise = contract.allPairs(i)
    const pairBalancePromise = pairBalanceOf(account, pairAddressPromise, provider)
    const pairNamePromise = getPairName(pairAddressPromise, provider)
    const pairToken0Promise = getPairToken(0, pairAddressPromise, provider)
    const pairToken1Promise = getPairToken(1, pairAddressPromise, provider)
    const token0SymbolPromise = getSymbol(pairToken0Promise, provider)
    const token1SymbolPromise = getSymbol(pairToken1Promise, provider)
    const reserve0Promise = getReserve(pairAddressPromise, provider)
    const totalSupplyPromise = getTotalSupply(pairAddressPromise)

    promises.push(
      pairAddressPromise,
      pairBalancePromise,
      pairNamePromise,
      pairToken0Promise,
      pairToken1Promise,
      token0SymbolPromise,
      token1SymbolPromise,
      reserve0Promise,
      totalSupplyPromise
    )
  }

  const results = await Promise.all(promises)

  for (let i = 0; i < totalPairs; i++) {
    const pairAddress = results[i * 9]
    const pairBalance = results[i * 9 + 1]
    const pairName = results[i * 9 + 2]
    const pairToken0 = results[i * 9 + 3]
    const pairToken1 = results[i * 9 + 4]
    const token0Symbol = results[i * 9 + 5]
    const token1Symbol = results[i * 9 + 6]
    const pairReserves = results[i * 9 + 7]
    const totalSupply = results[i * 9 + 8]
    const reserve0 = await parseWeiToValue(pairReserves[0] + "", pairToken0)
    const reserve1 = await parseWeiToValue(pairReserves[1] + "", pairToken1)
    const ratio = pairBalance / totalSupply
    const userReserve0 = reserve0 * ratio
    const userReserve1 = reserve1 * ratio

    let pairMeInformation = {
      pairAddress,
      pairBalance,
      pairName,
      pairToken0,
      pairToken1,
      token0Symbol,
      token1Symbol,
      pairReserves,
      totalSupply,
      reserve0,
      reserve1,
      ratio,
      userReserve0,
      userReserve1,
    }

    pairs.push(pairMeInformation)
  }

  return pairs
}

export const myLiquidity = async (tokenLP, provider) => {
  const reserve = await getReserve(String(tokenLP), provider)

  const token0Balance = Number(reserve[0]._hex, 16)

  const token1Balance = Number(reserve[1]._hex, 16)

  return [token0Balance, token1Balance]
}

const connectContract = (provider) => {
  return new Contract(FACTORY_ADDRESS, FACTORY_ABI, provider)
}
