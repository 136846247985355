import logo from "../images/logo.png"
import { ezkaliburUrlData } from "./pad/launchInfo"
import { SocialIcon } from "./socialIcon"

export const LAunchHeader = ({ urlData = ezkaliburUrlData, mainLogo = { logo }, description }) => {
  return (
    <div className="text-center text-white flex flex-col items-center font-semibold max-w-5xl px-3">
      <img src={mainLogo} className="h-16" />
      <p className="pb-3">{description}</p>
      <SocialIcon size={30} urlData={urlData} />
    </div>
  )
}
