import { useStore } from "@nanostores/react"
import { pairSwap } from "../../state/state"

export const Chart = () => {
  //eslint-disable-next-line
  const pair = useStore(pairSwap)
  return (
    <div className="w-[786px] h-[450px] rounded-md border-2 overflow-hidden border border-card-border">
      <iframe
        height="100%"
        width="100%"
        id="dexscreener-embed"
        title="Dexscreener Embed"
        src={`https://dexscreener.com/zksync/${pair}?embed=1&theme=dark&trades=0&info=0`}
        allowfullscreen
      ></iframe>
    </div>
  )
}
