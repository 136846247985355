import { Contract } from "@ethersproject/contracts"
import FARMING_ABI from "../ABI/FARMING_ABI.json"
import { FARMING_ADDRESS } from "../constants"
import { providerReadOnly } from "../state/state"

export const getPoolLength = async () => {
  const contract = connectContract()
  return await contract.poolLength()
}

export const totalAllocPoint = async (provider) => {
  const contract = connectContract(provider)
  return await contract.totalAllocPoint()
}

export const stakeLP = async (account, amount, idTokenLp, provider) => {
  const contract = connectContract(provider.getSigner())
  return contract.deposit(idTokenLp, amount, account)
}

export const claimRewards = async (account, idTokenLp, provider) => {
  const contract = connectContract(provider.getSigner())
  return contract.deposit(idTokenLp, "0", account)
}

export const userInfo = async (lpId, account) => {
  const contract = connectContract()
  return contract.userInfo(lpId, account)
}

export const getPoolInfo = async (lpId) => {
  const contract = connectContract()
  return contract.poolInfo(lpId)
}

export const pendingMeerkat = async (lpId, account, provider = providerReadOnly) => {
  const contract = connectContract(provider)
  return contract.pendingMeerkat(lpId, account)
}

export const unStakes = async (lpId, amount, provider) => {
  const contract = connectContract(provider.getSigner())

  return contract.withdraw(lpId, amount)
}

export const unStakesEmergency = async (lpId, provider) => {
  const contract = connectContract(provider.getSigner())
  return contract.emergencyWithdraw(lpId)
}

export const deposit = async (lpId, amount, account, provider) => {
  const contract = connectContract(provider.getSigner())
  return contract.deposit(lpId, amount, account)
}

const connectContract = (provider = providerReadOnly) => {
  return new Contract(FARMING_ADDRESS, FARMING_ABI, provider)
}
