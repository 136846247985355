import { useWeb3React } from "@web3-react/core"

export const ConnectionButton = ({ connector, img, name }) => {
  const { activate } = useWeb3React()

  const connection = async () => {
    activate(connector)
    localStorage.setItem("connection", JSON.stringify({ name }))
  }

  return (
    <button
      disabled={name !== "MetaMask"}
      onClick={connection}
      className="bg-transparent hover:bg-card-border border border-card-border text-white text-start font-bold py-2 mb-4 px-4 rounded-md border-2 w-full"
    >
      <div className="flex flex-row items-center text-gray-500">
        <img width={"40px"} className="pr-2" src={img} />
        {name}
      </div>
    </button>
  )
}
