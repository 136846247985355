import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { balanceOf } from "../../API/erc20"
import { Spinner } from "../spinner/Spinner"
import { parseWeiToValue } from "../../API/erc20"
import { ethers } from "ethers"
import { displayDecimals } from "../../utils"

export const TokenItem = ({ name, address, img, selectToken, exit }) => {
  const { library, account } = useWeb3React()
  const [currentBalance, setCurrentBalance] = useState("0.0")
  const [loading, setLoading] = useState(false)

  const checkBalance = () => {
    if (address === "ETH") {
      library.getBalance(account).then((value) => {
        setLoading(true)
        const parsed = +ethers.formatEther(value + "")
        setCurrentBalance(displayDecimals(parsed))
        setLoading(false)
      })
    } else {
      balanceOf(account, address, library).then((value) => {
        setLoading(true)
        parseWeiToValue(value, address, library).then((balance) => {
          setCurrentBalance(displayDecimals(balance))
          setLoading(false)
        })
      })
    }
  }

  useEffect(() => {
    if (!library) {
      setCurrentBalance("0")
      return
    }

    checkBalance()
  }, [library, account])

  const setAndExit = () => {
    selectToken(address, name)
    exit(false)
  }

  return (
    <div onClick={() => setAndExit()} className="flex justify-between mt-5 cursor-pointer">
      <div className="flex item-center">
        <img src={img} className="h-8" />
        <h5 className="text-white font-semibold ml-2.5">{name}</h5>
      </div>
      {loading ? (
        <Spinner size={"25"} />
      ) : (
        <div>
          <h5 className="text-pgray-color font-semibold">{currentBalance}</h5>
        </div>
      )}
    </div>
  )
}
