import { Web3ReactProvider } from "@web3-react/core"
import { Web3Provider } from "@ethersproject/providers"
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <Web3ReactProvider getLibrary={(provider) => new Web3Provider(provider)}>
    <App />
  </Web3ReactProvider>
)
