import React, { useRef } from "react"

export const Modal = ({ setShowModal, content }) => {
  const wrapperRef = useRef(null)

  function handleClickOutside(event) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setShowModal(false)
    }
  }

  document.addEventListener("mousedown", handleClickOutside)

  return (
    <>
      <div className="justify-center items-center flex fixed inset-0 z-50 bg-black bg-opacity-50">
        <div className="px-4" ref={wrapperRef}>
          {content}
        </div>
      </div>
    </>
  )
}
