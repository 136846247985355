// export const SWORD_ADDRESS = "0x41d962eD9a2Af1B68844EF588e182A628a5FA6f3" //TESTNET
// export const XSWORD_ADDRESS = "0xE9F04E418f93Ae73387576e9ab0f8FDB7C88545D" //TESTNET
// export const FACTORY_ADDRESS = "0x6aB757F91E67eB3bAc94dE98893F4c7f6389BdFa" //TESTNET
// export const FARMING_ADDRESS = "0x3A79e647f8F1b228Ed62F3EE4A06c66B85E979cd" //TESTNET
// export const ROUTER_ADDRESS = "0xE92e1F31190970fB1bb0cddaF29bF2D3e0271Caf" //TESTNET
// export const WETH_ADDRESS = "0x20b28B1e4665FFf290650586ad76E977EAb90c5D" //TESTNET
// export const RPC_URL = "https://zksync2-testnet.zksync.dev" //TESTNET
// export const PUBLIC_ICO_ADDRESS = "0xD0C1223D0850B2645bdc0B0bD93AF32Ccd731c54" //TESTNET
// export const PRIVATE_ICO_ADDRESS = "0x1c30ba4cB8c5b22692d8aFCb9d4e01f1bD3c8d97" //TESTNET
// export const SWORD_STAKE = "0x3e44cdE74D5beC8db1Fd542D05F896cd6A4eC98d"
// export const DIVIDENDS_ADDRESS = "0x15d28893b503ee9D358e4a8e54E610e41A0B2A62"
// export const CHAIN = 280
// export const CHAIN_OBJ = {
//   chainId: "0x118",
//   rpcUrls: ["https://testnet.era.zksync.dev"],
//   chainName: "zkSync Era Testnet",
//   nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
//   blockExplorerUrls: ["https://goerli.explorer.zksync.io/"],
// }
// export const EPOCH_DURATION = 1800000

export const CHAIN = 324
export const CHAIN_OBJ = {
  chainId: "0x144",
  rpcUrls: ["https://mainnet.era.zksync.io"],
  chainName: "zkSync Era Mainnet",
  nativeCurrency: { name: "ETH", decimals: 18, symbol: "ETH" },
  blockExplorerUrls: ["https://explorer.zksync.io/"],
}
export const WETH_ADDRESS = "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91" //MAINNET
export const SWORD_ADDRESS = "0x240f765Af2273B0CAb6cAff2880D6d8F8B285fa4" //MAINNET
export const XSWORD_ADDRESS = "0x11ef47783740B3F0c9736D54BE8eF8953C3Ead99" //MAINNET
export const ROUTER_ADDRESS = "0x498f7bB59c61307De7dEA005877220e4406470e9" //MAINNET
export const FACTORY_ADDRESS = "0x15C664A62086c06D43E75BB3fddED93008B8cE63" //MAINNET
export const FARMING_ADDRESS = "0x6a6d643BdB63Bc4D74D166ABeE57B1BE5b3AA9Ab" //MAINNET
export const PUBLIC_ICO_ADDRESS = "0x21EcD585b98C4CC74ca6c7DCb917a4Edf44ddcc5" //MAINNET
export const PRIVATE_ICO_ADDRESS = "0x3e44cdE74D5beC8db1Fd542D05F896cd6A4eC98d" //MAINNET
export const ZKDX_ICO = "0xBDb6845Fd5dCA99169cB7782571eb7BB5a65c0Be" //MAINNET
export const SWORD_STAKE = "0x713bAdB6CCB0584f3d3D2bE6b4672dB68B888D3c" //MAINNET
export const DIVIDENDS_ADDRESS = "0xAcAceAb8d76667866eacF8E112E7d73a01C1895f"
export const RPC_URL = "https://mainnet.era.zksync.io" //MAINNET
export const EPOCH_DURATION = 604800000
