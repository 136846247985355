import { useWeb3React } from "@web3-react/core"
import { addressSlice } from "../../utils"
import { MdContentCopy } from "react-icons/md"
import { MdOpenInNew } from "react-icons/md"
import { toast } from "react-toastify"
import { CHAIN, CHAIN_OBJ } from "../../constants"
import { useEffect } from "react"

export const ConnectionInfo = () => {
  const { account, chainId, deactivate, library, active } = useWeb3React()

  const openAccountOnScanner = () => {
    const test = chainId === 280 ? "goerli." : ""
    window.open(`https://${test}explorer.zksync.io/address/${account}`, "_blank", "noreferrer")
  }

  const copyAddressToClipBoard = () => {
    navigator.clipboard.writeText(account)
    toast("Address copied on clipboard")
  }

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x144" }],
      })
    } catch (switchError) {
      // 4902 error code indicates the chain is missing on the wallet
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [{ ...CHAIN_OBJ, chainId: "0x144" }],
          })
        } catch (error) {
          console.error(error)
        }
      }
    }
  }

  const doDeactivate = () => {
    deactivate()
    localStorage.removeItem("connection")
  }

  useEffect(() => {
    if (active && chainId !== CHAIN) {
      switchNetwork()
    }
  }, [active, chainId])

  return (
    <div className="h-auto w-80 pt-4 px-4 rounded-md border-2 border-card-border shadow bg-custom-color-solid">
      <p className="text-white text-start">Account</p>
      <div className="pt-6 pb-6 text-white">
        {chainId === CHAIN ? (
          <div className="flex flex-row relative">
            <p className="font-bold text-xl start-1">{addressSlice(account)}</p>
            <div className="absolute right-0">
              <button
                onClick={openAccountOnScanner}
                className="ml-2 bg-gray-600 p-1 rounded-md border-2 border-card-border"
              >
                <MdOpenInNew size={"23"} />
              </button>
              <button
                onClick={copyAddressToClipBoard}
                className="ml-2 bg-gray-600 p-1 rounded-md border-2 border-card-border"
              >
                <MdContentCopy size={"23"} />
              </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col text-center">
            <p>WRONG CHAIN</p>
            <button
              onClick={switchNetwork}
              className="p-2 w-full rounded-md tex-lg font-bold text-white mt-4 bg-orange-color"
            >
              SWITCH
            </button>
            <p className="pt-6">OR</p>
          </div>
        )}
        <div className="pt-2 flex flex-row">
          <button
            onClick={doDeactivate}
            className="p-2 w-full rounded-md tex-lg font-bold text-white mt-4 bg-orange-color"
          >
            Disconnect
          </button>
        </div>
      </div>
    </div>
  )
}
