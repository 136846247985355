import { SWORD_ADDRESS, WETH_ADDRESS } from "./constants"
import { providerReadOnly, swapConfig } from "./state/state"
import { ethers } from "ethers"

export const hexToInteger = (hexValue) => {
  return parseInt(hexValue, 16)
}

export const addressSlice = (address) => {
  return address.slice(0, 6) + "..." + address.slice(38, 42)
}

export const parseWeiToNumber = (value, decimal = 2) => {
  return Number(+ethers.formatEther(value + "")).toFixed(decimal)
}

export const parseStringToNumberDecimal = (value, decimal = 2) => {
  return Number(+value + "").toFixed(decimal)
}

export const parseNumberBase18 = (value, decimal = 2) => {
  return Number(+value / Math.pow(10, 18) + "").toFixed(decimal)
}

export const parseNumberPerBase18 = (value, decimal = 2) => {
  return parseFloat(+value * Math.pow(10, 18) + "").toFixed(decimal)
}

export const parseNumberToWeiETH = (value) => {
  return ethers.parseEther(value) + ""
}

export const getParsedBalance = async (address) => {
  const value = await providerReadOnly.getBalance(address)
  return parseWeiToNumber(value + "", 5)
}

const USDC_ADDRESS = "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4"
const USDT_ADDRESS = "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C"
const USD_PLUS_ADDRESS = "0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557"
const ZKDX_ADDRESS = "0xC3a3Aa99B80845d22cC41DEa3bDB9b2a9eBe199a"
const ONEZ_ADDRESS = "0x90059C32Eeeb1A2aa1351a58860d98855f3655aD"

export const buildPath = () => {
  const path = []

  const config = swapConfig.get()

  path.push(config.tokenA === "ETH" ? WETH_ADDRESS : config.tokenA)
  path.push(config.tokenB === "ETH" ? WETH_ADDRESS : config.tokenB)

  if (path[0] === path[1]) return undefined

  //route per usdc / sword
  if (path[0] === SWORD_ADDRESS && path[1] === USDC_ADDRESS) {
    return [path[0], WETH_ADDRESS, path[1]]
  }

  if (path[0] === USDC_ADDRESS && path[1] === SWORD_ADDRESS) {
    return [path[0], WETH_ADDRESS, path[1]]
  }

  //route per usdt / sword
  if (path[0] === SWORD_ADDRESS && path[1] === USDT_ADDRESS) {
    return [path[0], WETH_ADDRESS, USDC_ADDRESS, path[1]]
  }

  if (path[0] === USDT_ADDRESS && path[1] === SWORD_ADDRESS) {
    return [path[0], USDC_ADDRESS, WETH_ADDRESS, path[1]]
  }

  //route per usdt / eth
  if (path[0] === USDT_ADDRESS && path[1] === WETH_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  if (path[0] === WETH_ADDRESS && path[1] === USDT_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  //route usdt/usd+
  if (path[0] === USDT_ADDRESS && path[1] === USD_PLUS_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  if (path[0] === USD_PLUS_ADDRESS && path[1] === USDT_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  //route zkdx/usdt
  if (path[0] === ZKDX_ADDRESS && path[1] === USDT_ADDRESS) {
    return [path[0], WETH_ADDRESS, path[1]]
  }

  if (path[0] === USDT_ADDRESS && path[1] === ZKDX_ADDRESS) {
    return [path[0], WETH_ADDRESS, path[1]]
  }

  //route zkdx/usdc
  if (path[0] === ZKDX_ADDRESS && path[1] === USDC_ADDRESS) {
    return [path[0], WETH_ADDRESS, path[1]]
  }

  if (path[0] === USDC_ADDRESS && path[1] === ZKDX_ADDRESS) {
    return [path[0], WETH_ADDRESS, path[1]]
  }

  //route onez/weth
  if (path[0] === ONEZ_ADDRESS && path[1] === WETH_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  if (path[0] === WETH_ADDRESS && path[1] === ONEZ_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  //route onez/usdt
  if (path[0] === ONEZ_ADDRESS && path[1] === USDT_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  if (path[0] === USDT_ADDRESS && path[1] === ONEZ_ADDRESS) {
    return [path[0], USDC_ADDRESS, path[1]]
  }

  return path
}

export const abbreviateNumber = (number) => {
  let abbreviation = ""

  if (number >= 1000000) {
    number = (number / 1000000).toString()
    if (number.includes(".")) {
      const decimalIndex = number.indexOf(".")
      number = number.slice(0, decimalIndex + 3)
    }
    abbreviation = number + "M"
  } else {
    const decimalIndex = number.toString().indexOf(".")
    if (decimalIndex !== -1) {
      const decimals = number.toString().slice(decimalIndex + 1, decimalIndex + 3)
      abbreviation = number.toFixed(decimals.length)
    } else {
      abbreviation = number.toString()
    }
  }

  return abbreviation
}

export const convertToDecimalString = (numberString) => {
  if (numberString.includes("e")) {
    const number = parseFloat(numberString)
    return number.toFixed(0)
  }
  return numberString
}

export const removeDecimals = (number, decimals = 2) => {
  if (number == 0) {
    return 0
  }
  const multiplier = Math.pow(10, decimals)
  const roundedNumber = Math.floor(number * multiplier) / multiplier
  return roundedNumber
}

export const displayDecimals = (num) => {
  let str = num.toString()
  let decimalIndex = str.indexOf(".")

  if (decimalIndex === -1) {
    decimalIndex = str.indexOf(",")
  }

  if (decimalIndex === -1) {
    return str
  }

  let decimalPart = str.slice(decimalIndex + 1)
  if (decimalPart.length <= 2) {
    return str
  }

  let nonZeroIndex = -1
  for (let i = 0; i < decimalPart.length; i++) {
    if (decimalPart[i] !== "0") {
      nonZeroIndex = i
      break
    }
  }

  if (nonZeroIndex === -1 || nonZeroIndex < 2) {
    return str.slice(0, decimalIndex + 3)
  }

  const data = str.slice(0, decimalIndex + nonZeroIndex + 3)

  return data
}
