import axios from "axios"

export const getTVL = async () => {
  const response = await axios.get("https://api.llama.fi/protocol/ezkalibur")
  const tvlsArray = response.data.chainTvls["zkSync Era"].tvl
  let lastTvl = tvlsArray[tvlsArray.length - 1].totalLiquidityUSD

  if (lastTvl >= 1000000) {
    const millions = (lastTvl / 1000000).toFixed(2)
    return millions.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "M"
  } else {
    return lastTvl.toFixed(0)
  }
}
