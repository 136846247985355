import { useWeb3React } from "@web3-react/core"
import { useEffect, useState } from "react"
import { allowance2, approve, balanceOf, parseWeiToValue } from "../../API/erc20"
import { ROUTER_ADDRESS } from "../../constants"
import { Spinner } from "../spinner/Spinner"
import { DisabledButton } from "../utils/disabledButton"
import { toast } from "react-toastify"
import { ReceiptToast } from "../toast/receiptToast"
import { useStore } from "@nanostores/react"
import { addLiquidityApproval, addLiquidityState } from "../../state/state"
import { addLiquidity } from "../../API/router"
import { baseToken } from "../selectToken/baseToken"
import { ethers } from "ethers"
import noSymbol from "../images/tokens/noSymbol.png"
import { displayDecimals } from "../../utils"

export const AddLiquidity = ({ pair, tokenSelection, setting }) => {
  const { account, active, library } = useWeb3React()
  const approveUpdate = useStore(addLiquidityApproval)
  const [isLoadindBalance, setIsLoadingBalance] = useState(false)
  const [liquidityData, setLiquidityData] = useState({ valueTokenA: null, valueTokenB: null })
  const [userData, setUserData] = useState({
    parsedBalanceTokenA: 0,
    parsedBalanceTokenB: 0,
    balanceTokenA: null,
    balanceTokenB: null,
  })

  const setMax = (selector, value) => {
    let temp = { ...liquidityData }
    if (pair.pairAddress === ethers.ZeroAddress || (pair.reserve1 == 0 && pair.reserve0 == 0)) {
      temp[`valueToken${selector}`] = value
      setLiquidityData({ ...temp })
      return
    }
    temp = respectTheQuote(`valueToken${selector}`, value)
    setLiquidityData({ ...temp })
  }

  const getDecimals = (address) => baseToken.find((item) => item.address === address)?.decimals || 18

  const truncateNumber = (number, decimalPlaces) => {
    const scaleFactor = Math.pow(10, decimalPlaces)
    return number >= 0
      ? Math.floor(number * scaleFactor) / scaleFactor
      : Math.ceil(number * scaleFactor) / scaleFactor
  }

  const respectTheQuote = (name, value) => {
    let temp = { ...liquidityData }

    if (name === "valueTokenA") {
      temp[name] = value
      let valueB
      if (pair.token0 === tokenSelection.tokenA) {
        valueB = (pair.reserve1 / pair.reserve0) * value
      } else {
        valueB = (pair.reserve0 / pair.reserve1) * value
      }

      valueB = valueB.toString().replace(",", ".")
      temp.valueTokenB = valueB
    } else {
      temp[name] = value
      let valueA
      if (pair.token0 === tokenSelection.tokenB) {
        valueA = (pair.reserve1 / pair.reserve0) * value
      } else {
        valueA = (pair.reserve0 / pair.reserve1) * value
      }
      valueA = valueA.toString().replace(",", ".")
      temp.valueTokenA = valueA
    }

    temp.valueTokenA = truncateNumber(+temp.valueTokenA, getDecimals(tokenSelection.tokenA))
    temp.valueTokenB = truncateNumber(+temp.valueTokenB, getDecimals(tokenSelection.tokenB))

    return temp
  }

  const getUserData = async () => {
    setIsLoadingBalance(true)
    const [balanceTokenAResponse, balanceTokenBResponse, allowanceTokenAResponse, allowanceTokenBResponse] =
      await Promise.all([
        tokenSelection.tokenA === "ETH"
          ? library.getBalance(account)
          : balanceOf(account, tokenSelection.tokenA),
        tokenSelection.tokenB === "ETH"
          ? library.getBalance(account)
          : balanceOf(account, tokenSelection.tokenB),
        tokenSelection.tokenA === "ETH"
          ? library.getBalance(account)
          : allowance2(account, ROUTER_ADDRESS, tokenSelection.tokenA),
        tokenSelection.tokenB === "ETH"
          ? library.getBalance(account)
          : allowance2(account, ROUTER_ADDRESS, tokenSelection.tokenB),
      ])

    const parsedBalanceTokenA = await parseWeiToValue(balanceTokenAResponse, tokenSelection.tokenA)
    const parsedBalanceTokenB = await parseWeiToValue(balanceTokenBResponse, tokenSelection.tokenB)
    const parsedAllowanceTokenA = await parseWeiToValue(allowanceTokenAResponse, tokenSelection.tokenA)
    const parsedAllowanceTokenB = await parseWeiToValue(allowanceTokenBResponse, tokenSelection.tokenB)

    setUserData({
      parsedBalanceTokenA,
      parsedBalanceTokenB,
      balanceTokenA: balanceTokenAResponse,
      balanceTokenB: balanceTokenBResponse,
      parsedAllowanceTokenA,
      parsedAllowanceTokenB,
    })
    setIsLoadingBalance(false)
  }

  const handleInputChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    let temp = { ...liquidityData }

    if (pair.pairAddress === ethers.ZeroAddress || (pair.reserve1 == 0 && pair.reserve0 == 0)) {
      temp[name] = value
      setLiquidityData(temp)
      return
    }

    temp = respectTheQuote(name, value)

    setLiquidityData(temp)
  }

  const executeApprove = (value, contractAddress) => {
    approve(ROUTER_ADDRESS, value, contractAddress, library)
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              addLiquidityApproval.set(addLiquidityApproval.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  const executeAddLiquidity = () => {
    addLiquidity(
      tokenSelection.tokenA,
      tokenSelection.tokenB,
      liquidityData.valueTokenA,
      liquidityData.valueTokenB,
      account,
      setting.add.slippage,
      setting.add.deadLine,
      library
    )
      .then((tx) => {
        if (!tx) {
          toast("Transaction error!")
          return
        }
        toast(<ReceiptToast txHash={tx.hash} />)
        tx.wait()
          .then((res) => {
            if (res.status === 1) {
              toast("Transaction Success!")
              addLiquidityApproval.set(addLiquidityApproval.get() + 1)
              addLiquidityState.set(addLiquidityState.get() + 1)
            } else toast("Transaction Failed!")
          })
          .catch((e) => {
            console.log(e)
            toast("Transaction Failed!")
          })
      })
      .catch((e) => console.error(e))
  }

  useEffect(() => {
    if (!active) {
      setUserData({
        parsedBalanceTokenA: 0,
        parsedBalanceTokenB: 0,
        balanceTokenA: null,
        balanceTokenB: null,
      })
      return
    }

    getUserData()
  }, [active, account, tokenSelection, approveUpdate])

  return (
    <div className="flex flex-col w-full pt-3">
      <p className="self-end flex flex-row items-center">
        Available:{" "}
        {isLoadindBalance ? (
          <div className="pl-2">
            {" "}
            <Spinner size={20} />{" "}
          </div>
        ) : (
          <button onClick={() => setMax("A", userData.parsedBalanceTokenA)} className="pl-2">
            {displayDecimals(userData.parsedBalanceTokenA)}
          </button>
        )}
      </p>
      <div className="w-full h-[50px] rounded-md border-2 border-2 border-card-border flex flex-row justify-between items-center mt-2 px-3">
        <div className="flex flex-row gap-2 items-center">
          <img
            className="w-5"
            src={baseToken.find((item) => item.address === tokenSelection.tokenA)?.img || noSymbol}
          />
          <p>{tokenSelection.symbolA}</p>
        </div>
        <input
          name="valueTokenA"
          onChange={handleInputChange}
          className="w-full bg-transparent text-end"
          type="number"
          placeholder="0"
          value={liquidityData.valueTokenA}
        />
      </div>
      <div className="w-full text-center text-3xl pt-4">+</div>
      <p className="self-end flex flex-row items-center">
        Available:{" "}
        {isLoadindBalance ? (
          <div className="pl-2">
            {" "}
            <Spinner size={20} />{" "}
          </div>
        ) : (
          <button onClick={() => setMax("B", userData.parsedBalanceTokenB)} className="pl-2">
            {displayDecimals(userData.parsedBalanceTokenB)}
          </button>
        )}
      </p>
      <div className="w-full h-[50px] rounded-md border-2 border-2 border-card-border flex flex-row justify-between items-center mt-2 px-3">
        <div className="flex flex-row gap-2 items-center">
          <img
            className="w-5"
            src={baseToken.find((item) => item.address === tokenSelection.tokenB)?.img || noSymbol}
          />
          <p>{tokenSelection.symbolB}</p>
        </div>
        <input
          name="valueTokenB"
          onChange={handleInputChange}
          className="w-full bg-transparent text-end"
          type="number"
          placeholder="0"
          value={liquidityData.valueTokenB}
        />
      </div>
      {!active ? (
        <DisabledButton text={"Connect Wallet First!"} />
      ) : +liquidityData.valueTokenA > +userData.parsedBalanceTokenA ||
        +liquidityData.valueTokenB > +userData.parsedBalanceTokenB ? (
        <DisabledButton
          text={`Amount ${
            +liquidityData.valueTokenA > +userData.parsedBalanceTokenA
              ? tokenSelection.symbolA
              : tokenSelection.symbolB
          } exceed balance`}
        />
      ) : +liquidityData.valueTokenA > +userData.parsedAllowanceTokenA ||
        +liquidityData.valueTokenB > +userData.parsedAllowanceTokenB ? (
        <div className="flex flex-row gap-4">
          {+liquidityData.valueTokenA > +userData.parsedAllowanceTokenA ? (
            <button
              onClick={() => executeApprove(liquidityData.valueTokenA, tokenSelection.tokenA)}
              className="mt-4 w-full h-[50px] bg-orange-color rounded-md"
            >
              Approve {tokenSelection.symbolA}
            </button>
          ) : (
            ""
          )}
          {+liquidityData.valueTokenB > +userData.parsedAllowanceTokenB ? (
            <button
              onClick={() => executeApprove(liquidityData.valueTokenB, tokenSelection.tokenB)}
              className="mt-4 w-full h-[50px] bg-orange-color rounded-md"
            >
              Approve {tokenSelection.symbolB}
            </button>
          ) : (
            ""
          )}
        </div>
      ) : (
        <button onClick={executeAddLiquidity} className="mt-4 w-full h-[50px] bg-orange-color rounded-md">
          Add Liquidity
        </button>
      )}
    </div>
  )
}
